import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { api, CleverFormattedMessage } from 'videokube-front-library';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Typography } from "@material-ui/core";
import { AppState } from "../../store";
import { useSelector } from "react-redux";
import { IFile } from "videokube-front-library/dist/types";
import { Advertise } from "../../components/Advertise";

const { confirmRegister } = api;

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'white', 
      '& a': {
        textDecoration: 'underline'
      },
      height: "100%",
      textAlign: "center",
      paddingTop: "60px",
    },
    logo: {
        margin: 20,
        maxWidth: 170,
        maxHeight: 170,
        [theme.breakpoints.down("sm")]: {
          maxWidth: 120
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: "2rem",
        marginBottom: "20px"
    },
    description: {
        marginBottom: "40px",
        fontSize: "1.2rem"
    }
}));

export function ThankYou() {
    let {uuid} = useParams();
    const classes = useStyles();

    const [titleKey, setTitleKey] = useState<string | null>(null);
    const [descriptionKey, setDescriptionKey] = useState<string | null>(null);

    const files = useSelector<AppState, IFile[]>((state) => state.app.files);

    useEffect(() => {
        confirmRegister(uuid as string).then(() => {
            setTitleKey("registration_confirmation_title");
            setDescriptionKey("registration_confirmation_description");
        })
        .catch((err) => {
            if(err.response.status === 403) {
                setTitleKey("registration_expiration_title");
                setDescriptionKey("registration_expiration_description");
            } else {
                setTitleKey("registration_error_title");
                setDescriptionKey("registration_error_description");
            }
        });
    },[uuid]);

    const logo = files.find(file => file.name === "logo");
    if(!logo) {
        return (<div>Logo not found</div>);
    }

    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    {titleKey !== null && (
                        <Typography className={classes.title} color="primary" component="h1" variant="overline">
                            <CleverFormattedMessage id={titleKey}/>
                        </Typography>
                    )}
                    {descriptionKey !== null && (
                        <Typography variant="overline" component="p" className={classes.description}>
                            <CleverFormattedMessage id={descriptionKey}></CleverFormattedMessage>
                        </Typography>
                    )}

                    <Advertise spreadWordKey="meantime_spread_word" thankYouKey="thank_you"/>
                </div>
            </Container>
        </div>
    );
}
