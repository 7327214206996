import React, { useState, useRef } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { makeStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CleverFormattedMessage, videokubeUtils } from 'videokube-front-library';
import { Box, TextField, Button, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { AppState } from '../../store';
import {IFile, Category as ICategory, Category, IRegister } from "videokube-front-library/dist/types";
import { useIntl } from "react-intl";
import CssBaseline from '@material-ui/core/CssBaseline';
//import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import {useSelector} from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { api, data, utils } from 'videokube-front-library';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { Loader } from '../../components/Loader';
import ReCAPTCHA from 'react-google-recaptcha';
const { sendRegister } = api;
const { sleep } = utils;

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'white', 
      '& a': {
        textDecoration: 'underline'
      }
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    logo: {
      margin: 20,
      maxWidth: 170,
      maxHeight: 170,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 120
      },
    },
    icon: {
      fontSize: '3.3rem',
      margin: '0px 7px'
    },
    checkbox: {
      color: 'black !important'
    },
    autocomplete: {
      marginTop: '16px',
      marginBottom: '8px'
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    link: {
      color: theme.palette.primary.main,
    }
}));

const peopleTypes = [
  { label: 'Researcher' },
  { label: 'Student' },
  { label: 'Business' },
  { label: 'Content Producer' },
  { label: 'Journalist' },
  { label: 'Advertiser' },
  { label: 'NGO / Institution' },
  { label: 'Administration / Government' },
  { label: 'Other' },
];

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

interface PeopleTypeType {
  label: string;
}

export function Register() {
    const snackbar = useSnackbar();
    const classes = useStyles();
    const files = useSelector<AppState, IFile[]>((state) => state.app.files);
    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [organization, setOrganization] = useState<string>('');
    const [position, setPosition] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [peopleType, setPeopleType] = useState<string>('');
    const [newsletter, setNewsletter] = React.useState( false );
    const [mail, setMail] = useState<boolean>(false);

    let history = useHistory();

    const { formatMessage } = useIntl();

    let categs = useSelector<AppState, ICategory[]>(state => state.app.categories);
    let categsState: Record<string,boolean> = {};
    categs.forEach(categ => { categsState[categ.slug] = false; });
    const [interest, setInterest] = useState<String[]>([]);
    const [allInterest, setAllInterest] = React.useState(false);
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const firstnameLabel = formatMessage({id: 'register_label_firstname'});
    const lastnameLabel = formatMessage({id: 'register_label_lastname'});
    const emailLabel = formatMessage({id: 'register_label_email'});
    const organizationLabel = formatMessage({id: 'register_label_organization'});
    const positionLabel = formatMessage({id: 'register_label_position'});
    const countryLabel = formatMessage({id: 'register_label_country'});
    const postalCodeLabel = formatMessage({id: 'register_label_postal_code'});
    const interestLabel = formatMessage({id: 'register_label_interest'});
    const newsletterLabel = formatMessage({id: 'register_label_newsletter'});
    const peopleTypeLabel = formatMessage({id: 'register_label_people_type'});
    const loginLabel = formatMessage({id: 'register_label_login'});
    const all_interests = formatMessage({id: 'all_interests'});

    const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
      event.preventDefault();

      //What if it takes too much time? Maybe set a limit?
      const recaptcha =  await recaptchaRef?.current?.executeAsync();

      if(!recaptcha){
          return snackbar.showMessage(formatMessage({id: 'register_label_unable_to_send'}));
      }

      let toSubmit: IRegister = {
        captcha: recaptcha,
        email,
        username: email,
        profile: {
          country,
          firstname, lastname,
          extraParams: {
            interests: interest,
            newsletter,
            organisation: organization,
            position,
            postcode: postalCode,
            profession: peopleType,
          }
        }
      };

      type toSubmitKey = keyof typeof toSubmit;

      //TODO: we should make uniform organisation and organization
      const requiredFields: {key: toSubmitKey,label: string}[] = [
        {key:'profile',label: firstnameLabel},
        {key:'profile',label: lastnameLabel},
        {key:'email',label: emailLabel},
        {key:'profile',label: organizationLabel},
        {key:'profile',label: positionLabel}
      ];

      for(let aRequiredField of requiredFields){
        if(!toSubmit[aRequiredField.key]){
          snackbar.showMessage(`The field '${aRequiredField.label}' is required`);
          return;
        }
      }

      setMail(true);

      try{
          console.log(toSubmit);
        await sendRegister(toSubmit);
      }catch(e){
        //TODO: i18n
        snackbar.showMessage(e?.response.data.message || "An error happened while submitting the form");
        setMail(false);
        return;
      }

      //TODO: this should also be internationalized
      snackbar.showMessage('Register success : check your emails');
      await sleep(3000);
      history.push('/');
    };

    const handleChangeCountry = (value: any) => {
        setCountry(value?.label || "");
    };

    const handleChangePeopleType = (value: any) => {
        setPeopleType(value.label);
    };

    const logo = files.find(file => file.name === "logo");
    if(!logo){
      return (<div>Logo not found</div>)
    }

    const handleChangeInterest = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = String(event.target.value);
      setAllInterest(false);
      if(interest.includes(newValue)){
        return setInterest(interest.filter(aValue => aValue !== newValue));
      }
      setInterest([
        ...interest, String(event.target.value)
      ]);
    };

    const handleAllInterest = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!allInterest) {
        setInterest(categs.map((categ) => categ.slug));
        setAllInterest(true);

      } else {
        setInterest([]);
        setAllInterest(false);
      }
    };

    const handleChangeNewsletter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewsletter(event.target.checked);
    };

    if(mail){
      return <Loader />
    }

    return <div className={classes.root}>

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src={logo.file} alt="Logo" />

        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={firstnameLabel}
            autoFocus
            onChange={(e) => setFirstname(e.target.value)}
            value={firstname}
            size="small"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={lastnameLabel}
            onChange={(e) => setLastname(e.target.value)}
            value={lastname}
            size="small"
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={emailLabel}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            size="small"
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={organizationLabel}
            type="lastname"
            onChange={(e) => setOrganization(e.target.value)}
            value={organization}
            size="small"
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={positionLabel}
            onChange={(e) => setPosition(e.target.value)}
            value={position}
            size="small"
          />

          <Autocomplete
            size="small"
            id="country-select-demo"
            options={data.countries}
            classes={{ 
              root: classes.autocomplete,
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => {
              handleChangeCountry(value);
            }}
            renderOption={(option) => (
              <React.Fragment>
                <span>{countryToFlag(option.code)}</span>
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={countryLabel}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={postalCodeLabel}
            onChange={(e) => setPostalCode(e.target.value)}
            value={postalCode}
            size="small"
          />

          <Autocomplete
            size="small"
            id="people-type-select-demo"
            options={peopleTypes as PeopleTypeType[]}
            classes={{ 
              root: classes.autocomplete,
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => {
              handleChangePeopleType(value);
            }}
            inputValue={peopleType}
            renderOption={(option) => (
              <React.Fragment>
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={peopleTypeLabel}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />

          <Box mt={1} mb={1}>
            <FormControl component="fieldset">
                <FormLabel component="legend">{interestLabel}</FormLabel>
                <FormGroup>
                    <Grid container>
                        {categs.map((categ: Category) =>
                            <Grid item xs={6} md={4} lg={4} key={categ.id}>
                                <FormControlLabel
                                    control={<Checkbox checked={interest.includes(categ.slug)} value={categ.slug} onChange={handleChangeInterest} name={categ.slug} className={classes.checkbox} />}
                                    label={categ.name}
                                />
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={6} md={4} lg={4} key={10}>
                      <FormControlLabel
                          control={<Checkbox checked={allInterest} onChange={handleAllInterest} name="all" className={classes.checkbox} />}
                          label={all_interests}
                      />
                    </Grid>
                </FormGroup>
            </FormControl>
          </Box>

          <Box mt={2} mb={2}>
            <FormControl component="fieldset">
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={newsletter} onChange={handleChangeNewsletter} name="newsletter" className={classes.checkbox} />}
                        label={newsletterLabel}
                    />
                </FormGroup>
            </FormControl>
          </Box>

          <Grid container justify="center">
            <Grid item>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={videokubeUtils.getCaptchaSiteKey()}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={mail}
                >
                    <CleverFormattedMessage id="register_submit_button" />
                </Button>
            </Grid>
          </Grid>
        </form>

        <Box mt={2} mb={10}>
          <Grid container justify="center">
            <Grid item>
              <RouterLink to="/login" className={classes.link}>{loginLabel}</RouterLink>
            </Grid>
          </Grid>
        </Box>

      </div>
    </Container>

    </div>;
}
