import React, { useState  } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { SimpleCard, HeaderImage, SubTitle, PaperCard, CleverFormattedMessage } from 'videokube-front-library';
import { Container, Box, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { IScientificPaper, IText, Media, IFile } from "videokube-front-library/dist/types";
import { responsiveHeaderImage, simpleCardNewStyle, seeAllStyleEndBlock, simpleCardInfographicsNewStyle } from '../theme';
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
//import { getUrlFromScienticPaper } from '../utils/utils';
import { ResponsiveWithSlugReviveAd } from './ReviveAd';
import { useIntl } from 'react-intl';
import ShowMoreText from "react-show-more-text";
import {useSelector} from "react-redux";
import { AppState } from '../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
    container: {
        paddingLeft: "50px",
        paddingRight: "50px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        }
    },
    papers: {
        marginBottom: '20px'
    },
    svg: {
      verticalAlign: "text-bottom",
      fontSize: "10px"
    },
    ad: {
        marginTop: '40px',
    },
    card: {
        //borderRadius: "3px",
        //border: "1px solid #9b9b9b",
        paddingTop: "40px",
        height: "100%"
    },
    description: {
        //lineHeight: "13px",
        color: "#999999",
        opacity: "0.9",
        fontSize: "1em",
    },
}));

interface GenericMediaViewerProps {
    videos: Media[];
    scientificPapers: IScientificPaper[];
    infographics: Media[];
    title: string;
    headerImg?: string;
    shouldDisplayScientificPapers?: boolean;
}

export function GenericMediaViewer(props:GenericMediaViewerProps) {
    const {scientificPapers, videos, infographics, title, headerImg, shouldDisplayScientificPapers} = props;
    
    const files = useSelector<AppState, IFile[]>((state) => state.app.files);
    const classes = useStyles();
    let { slug } = useParams<{slug:string}>();
    let dynamicHeaderImg = headerImg;

    // See more
    const defaultView = 12;
    const [nbView, setNbView] = useState<Record<string,number>>({video:defaultView,infographic:defaultView, scientificPaper: 6});

    const texts = useSelector<AppState, IText[]>((state) => state.app.texts);

    let nbVideos = videos?.length;
    let nbInfographics = infographics?.length;
    let nbScientificPapers = scientificPapers?.length;
    let videosSlice = videos?.slice(0, nbView['video']);
    let infographicsSlice = infographics?.slice(0, nbView['infographic']);
    let scientificPaperSlice = scientificPapers.slice(0, nbView['scientificPaper']);
    const { formatMessage } = useIntl();
    const undefineText = formatMessage({id: 'undefine_text'});
    let slug_description = undefined;
    const theme = useTheme();

    // Find description with slug
    if (slug) {
        slug_description = texts.find(x => x.key === slug.replace(/-/g,"_")  + "_description");
    }
    const description_read_more = formatMessage({id: 'description_read_more'});
    const description_read_less = formatMessage({id: 'description_read_less'});

    // Find img from directus with slug
    if (!headerImg){
        let imgTitle = "";
        if (slug) {
            imgTitle = slug.replace(/-/g,"_") + "_header";
        }
        const headerFile = files.find((file) => file.name === imgTitle );
        dynamicHeaderImg = headerFile?.file || undefined;
    }

    const showMoreFunction = (type:string) => async (event: React.FormEvent<HTMLElement>) => {
        const newNbView = {...nbView};
        newNbView[type] = newNbView[type] + defaultView;
        setNbView(newNbView);
    }

    return <div className={classes.root}>
        {dynamicHeaderImg && (
            <HeaderImage style={responsiveHeaderImage(theme)} title={title} img={dynamicHeaderImg + "?size=original"}/>
        )}
        {!dynamicHeaderImg && (
            <HeaderImage style={responsiveHeaderImage(theme)} title={title}/>
        )}

        {/* <div className={classes.ad}>
            <ResponsiveWithSlugReviveAd slug={slug}/>
        </div> */}

       {slug_description && (
            <Container maxWidth="lg">
                <Grid container className={classes.card}>
                    <Typography
                    component="div"
                    variant="caption"
                    gutterBottom
                    className={classes.description}
                    >
                        <ShowMoreText
                            lines={2}
                            more={description_read_more}
                            less={description_read_less}
                            anchorClass={"MuiTypography-subtitle1 "}
                        >
                            <CleverFormattedMessage id={slug_description.key}/>
                        </ShowMoreText>
                    </Typography>
                </Grid>
            </Container>
        )}

        <Container maxWidth={false} className={classes.container}>

        {videos && videosSlice && videosSlice.length > 0 && (
            <Box flexGrow={1}>
                <SubTitle text="videos" subText={'(' + nbVideos + ' items)'}/>
            
                <Grid container spacing={3}>
                    {videosSlice.map((video, i) =>
                        <Grid key={i} item xs={6} md={3}>
                            <SimpleCard
                                title={video.productionYear ? video.title + " (" + video.productionYear + ")" : video.title}
                                duration={video.duration}
                                img={video.preview+"?size=medium"}
                                category={video.providerName ? video.providerName : undefined}
                                type="video"
                                path={'/vod/' + video.slug}
                                style={simpleCardNewStyle}
                            />
                        </Grid>
                    )}
                    { videos.length > nbView['video'] && (
                    <Grid container justify="flex-end">
                        <Button color="primary" onClick={showMoreFunction('video')}><CleverFormattedMessage id="see_more_videos" className={seeAllStyleEndBlock}/><ArrowForwardIosIcon className={classes.svg} /></Button>
                    </Grid>
                    )}
                </Grid>
            </Box>
            )}

            {infographics && infographicsSlice && infographicsSlice.length > 0 && (
                <Box mt={6}>

                    <SubTitle text="infographics" subText={'(' + nbInfographics + ' items)'}/>

                    <Grid container spacing={3}>
                        {infographicsSlice.map((infographic, i) =>
                            <Grid key={i} item xs={6} md={3}>
                                <SimpleCard
                                    style={simpleCardInfographicsNewStyle}
                                    title={infographic.productionYear ? infographic.title + " (" + infographic.productionYear + ")" : infographic.title}
                                    category={infographic.providerName + ' - ' + infographic.typeOfContent}
                                    type="pdf"
                                    img={infographic.preview}
                                    path={"/infographics/" + infographic.slug}
                                />
                            </Grid>
                        )}
                        { infographics.length > nbView['infographic'] && (
                        <Grid container justify="flex-end">
                            <Button color="primary" onClick={showMoreFunction('infographic')}><CleverFormattedMessage id="see_more_infographics" className={seeAllStyleEndBlock}/><ArrowForwardIosIcon className={classes.svg} /></Button>
                        </Grid>
                        )}
                    </Grid>
                </Box>
            )}

            {scientificPapers && scientificPaperSlice && shouldDisplayScientificPapers && (
                <Box mt={6}>
                    <SubTitle text="scientific_papers" subText={'(' + nbScientificPapers + ' items)'}/>

                    <Grid container spacing={3}>
                        {scientificPaperSlice.map((scientificPaper, i) =>
                            <Grid key={i} item xs={12} md={6}>
                                <a href={scientificPaper.content} key={scientificPaper.content} rel="noopener noreferrer" target="_blank">
                                    <PaperCard
                                        title={scientificPaper.title}
                                        category="Article"
                                        link="PDF"
                                        date={/*TODO: maybe do better with dates! */ (scientificPaper.productionYear || undefineText).toString()}
                                        source="core.ac.uk"
                                        description={scientificPaper.description}
                                        showMore={true}
                                    />
                                </a>
                            </Grid>
                        )}
                        { scientificPapers.length > nbView['scientificPaper'] && (
                        <Grid container justify="flex-end">
                            <Button color="primary" onClick={showMoreFunction('scientificPaper')}><CleverFormattedMessage id="see_more_scientific_papers" className={seeAllStyleEndBlock}/><ArrowForwardIosIcon className={classes.svg} /></Button>
                        </Grid>
                        )}
                    </Grid>
                </Box>
            )}
        </Container>
    </div>
    ;
}
