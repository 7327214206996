import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IScientificPaper, Media } from "videokube-front-library/dist/types";
import { useSnackbar } from 'material-ui-snackbar-provider';
import { api } from 'videokube-front-library';
import { GenericMediaViewer } from '../../components/GenericMediaViewer';
import { Loader } from '../../components/Loader';
const { getScientificPapersWithKeyword, getMedias } = api;

export function Keyword(){
    const [scientificPapers, setScientificPapers] = useState<Array<IScientificPaper>>([]);
    const [linkedMedias, setLinkedMedias] = useState<Array<Media>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const snackbar = useSnackbar();
    
    let { slug } = useParams<{slug:string}>();
    
    let infos = linkedMedias.filter(media => media.typeOfContent?.toLowerCase() === "infographics").slice(0, 8);
    let videos = linkedMedias.filter(media => media.typeOfContent?.toLowerCase() === "videos");


    let shouldDisplayScientificPapers = true;
    //let headerImage: string | undefined;
    // // Specific Keyword for another design experience
    if(slug === 'kids'){
      shouldDisplayScientificPapers = false;
    }

    
    useEffect(() => {
      if (!slug) {
        return;
      }

      const fetchLinkedMedias = async () => {
        let linkedMedias = await getMedias({keyword:slug, limit: 1000});
        setLinkedMedias(linkedMedias);
        setLoading(false);
      };

      if (shouldDisplayScientificPapers){
        const goPapers = async () => {
            try {
                let sciPap = await getScientificPapersWithKeyword(slug);
                setScientificPapers(sciPap);
            } catch(e) {
              snackbar.showMessage(e.message);
              return;
            }
        }
        goPapers();
      }
      fetchLinkedMedias();
    }, [snackbar, slug, shouldDisplayScientificPapers]);

    if (!slug) {
        return <div>No tag!</div>;
    }
        
    if (!loading && !videos.length) {
        return <div>This tag has no videos in it!</div>;
    }

    if (loading){
        return (
            <div style={{margin: '20px'}}>
            <Loader height='5vh' size='50px'/>
            </div>
        )
    }



    return <GenericMediaViewer videos={videos} infographics={infos} title={slug} scientificPapers={scientificPapers} shouldDisplayScientificPapers={shouldDisplayScientificPapers}/>
}