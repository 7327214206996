import React from 'react';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { HeaderImage, useLibWrapper} from 'videokube-front-library';
import { Container, Divider, Typography} from '@material-ui/core';
import {useSelector} from "react-redux";
import { IFile } from "videokube-front-library/dist/types";
import {useIntl} from "react-intl";
import { AppState } from '../../store';
import { responsiveHeaderImage } from '../../theme';
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minHeight: "calc(100vh - 70px - 90px)",
        backgroundColor: theme.palette.secondary.main,
        paddinBottom: "40px",
    },
    accountInfo: {
        marginTop: "24px",
    },
    textAccount: {
        margin: "20px",
        marginLeft: "0",
    },
    divider: {
        height: '2px',
        backgroundColor: theme.palette.primary.main,
        width: "60%", 
        margin: "auto",
    },
    link: {
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        textTransform: 'capitalize',
        padding: '0',
        marginBottom: "20px",
    }
    
}));

export function Account() {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const userInfo = useLibWrapper();
    const files = useSelector<AppState, IFile[]>((state) => state.app.files);
    const headerImage = files.find((file) => file.name === "account_header");

    const accountTitle = formatMessage({id: 'account_title'});
    const usernameLabel = formatMessage({id: 'login_label_username'});
    const accountSubtitle= formatMessage({id: 'account_subtitle'});
    const logoutLabel = formatMessage({id: 'logout_label'});

    return <div className={classes.root}>
        {headerImage && (
            <HeaderImage title={accountTitle} style={responsiveHeaderImage(theme)} img={headerImage.file+"?size=original"}/>
        )}
        {!headerImage && (
            <HeaderImage title={accountTitle} style={responsiveHeaderImage(theme)} color={theme.palette.primary.main}/>
        )}

        <Divider className={classes.divider}/>
        <Container maxWidth="md">
            <Grid container justify="center">
                <Grid item key={"account_info"} xs={12} className={classes.accountInfo}>
                    <Typography color="primary" component="h4" variant="h4">{accountSubtitle}</Typography>
                    <Typography className={classes.textAccount} color="primary" component="h6" variant="h6">{usernameLabel} : {userInfo.getUsername()}</Typography>
                    
                    <div>
                        <Button className={classes.link} onClick={userInfo.logout}>{logoutLabel}</Button>
                    </div>
                </Grid>
            </Grid>
        </Container>
    </div>
};
