import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import {useSelector} from "react-redux";
import { AppState } from '../../store';
import { useSnackbar } from 'material-ui-snackbar-provider';
import {Category as ICategory, Media, IScientificPaper } from "videokube-front-library/dist/types";
import { api } from 'videokube-front-library';
import { uniqBy, orderBy } from "lodash";
import { GenericMediaViewer } from '../../components/GenericMediaViewer';
//import { getCategories } from 'videokube-front-library/dist/api';
import {Helmet} from "react-helmet";
const { getMedias } = api;

//TODO: prevent the api stuff that blocks imports!

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
}));

export function Category() {
    const categories =  useSelector<AppState,ICategory[]>(state => state.app.categories);
    const snackbar = useSnackbar();
    const [scientificPapers] = useState<Array<IScientificPaper>>([]);
    const [categoryMedias, setCategoryMedias] = useState<Array<Media>>([]);

    let { slug } = useParams<{slug:string}>();
    let category = categories.find(cat => cat.slug === slug);

    //getCategoryAndStoreInCache!
    //We prefer to ask for renew category!
    //If change language, we must reload everything!

    useEffect(() => {
        // const getPapers = async () => {
        //     if (!slug) {
        //         return;
        //     }

        //     try {
        //         let papers = await getScientificPapersWithCategory(slug);
        //         setScientificPapers(papers);
        //     } catch(e) {
        //         snackbar.showMessage(e.message);
        //         return;
        //     }
        // }
        const fetchMedias = async () => {
            if (!slug) {
                return;
            }

            try {
                let categoryMediasToSet = await getMedias({category:slug, limit: 1000});
                setCategoryMedias(categoryMediasToSet);
            } catch(e) {
                snackbar.showMessage(e.message);
                return;
            }
        }

        //getPapers();
        fetchMedias();
    }, [snackbar, slug]);
    
    let videos:Media[] = [];
    let infographics:Media[] = [];
    let scientificPapersSort:IScientificPaper[] = [];

    if (category) {
        videos = categoryMedias.filter(media => media.typeOfContent?.toLowerCase() === "videos");
        videos = orderBy(uniqBy(videos, x => x.slug), x => x.id,"desc");
        infographics = categoryMedias.filter(media => media.typeOfContent?.toLowerCase() === "infographics")
        //TODO: we should maybe parse as int or moment before!
        infographics = orderBy(infographics,x => x.productionYear,"desc");
        //TODO: WTF is this DOUBLE space between scientific and paper!
        const scientificPapersFromCategory = categoryMedias.filter(media => media.typeOfContent?.toLowerCase() === "scientific  paper");
        scientificPapersSort = [...scientificPapersFromCategory,...orderBy(scientificPapers,x => x.productionYear,"desc")];
    }
    const classes = useStyles();
    if(!category){
        return <div>This category does not exist!</div>;
    }

    return <div className={classes.root}>

        <Helmet>
            {category.title && <meta property="og:title" content={category.title} />}
            {category.description && <meta property="og:description" content={category.description} />}
            {category.preview && <meta property="og:image" content={category.preview} />}
        </Helmet>
        <GenericMediaViewer videos={videos} infographics={infographics} scientificPapers={scientificPapersSort} shouldDisplayScientificPapers={true} title={category.name} headerImg={category.visual}/>

    </div>
    ;
}
