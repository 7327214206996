import React, {useState, useEffect} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { SimpleCard, Video, PaperCard, VideoInformation, SubTitle, CleverFormattedMessage, stripHtml, useSuffixTitle, useSuffixDescription } from 'videokube-front-library';
import { Container, Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { api } from 'videokube-front-library';
import { Media as IVideo, Category, VideoLink, IScientificPaper } from 'videokube-front-library/dist/types';
import {useSelector} from "react-redux";
import { AppState } from '../../store';
//import { Link } from 'react-router-dom';
import { simpleCardNewStyle, simpleCardInfographicsNewStyle, videoStyle } from '../../theme';
import { orderBy } from 'lodash';
//import { getUrlFromScienticPaper } from '../../utils/utils';
import { ResponsiveWithSlugReviveAd } from '../../components/ReviveAd';
import { useIntl } from 'react-intl';
import {Helmet} from "react-helmet";
const { getVideoLink, getIdWithSlug, getScientificPapersWithMedia, getMedias } = api;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
    container: {
        paddingLeft: "50px",
        paddingRight: "50px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        }
    },
    exploreTitle: {
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '9.6px',
        color: '#9B9B9B',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.55rem',
            letterSpacing: '5px'
        }
    },
    description: {
        lineHeight: '24px',
        marginTop: '40px'
    },
    similarVideos:{
      marginBottom: '24px'
    },
    papers: {
      marginBottom: '20px'
    },
    placeholder: {
      background: '#f8f8f8',
      height: 'calc(100vh - 74px)',
      [theme.breakpoints.down('xl')]: {
        height: '56.25vw',
        width: '100vw'
      }
    }
}));

export function Vod() {
    const classes = useStyles();

    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [videoPreview, setVideoPreview] = useState<string>('');
    //const [videoDescription, setVideoDescription] = useState<string | undefined>(undefined);
    const [video, setVideo] = useState<IVideo>();
    const [papers, setPapers] = useState<Array<IScientificPaper>>([]);

    const [linkedMedias, setLinkedMedias] = useState<IVideo[]>([]);

    let { slug } = useParams<{slug:string}>();
    const snackbar = useSnackbar();
    const categories = useSelector<AppState, Category[]>(state => state.app.categories);
    const number_of_related_videos =
    (useSelector<AppState>(
      (state) => state.app.interfaceConfiguration?.number_of_related_videos,
    ) as number) || 12;

    const { formatMessage } = useIntl();
    const undefineText = formatMessage({id: 'undefine_text'});

    useEffect(() => {
      if (!slug) {
        return;
      }
      const goPapers = async () => {
        try {
          let sciPap = await getScientificPapersWithMedia(slug);
          setPapers(sciPap);
        } catch(e) {
          snackbar.showMessage(e.message);
          return;
        }
      }
      
      const getVideoIdWithSlug = async () => {
        const videosFromSlug = await getIdWithSlug(slug);
        if (!videosFromSlug) {
          return;
        }
        //TODO: why ever is this an array?
        let video = videosFromSlug[0];
        if (!video) {
          return;
        }
        setVideo(video);
        let linkedMedias = await getMedias({relatedToMedia:video.id, limit: 1000});
        setLinkedMedias(linkedMedias);
        
        //We reset values
        setVideoUrl(null);
        setVideoPreview("");
        let videoInfo: VideoLink;
        try{
          videoInfo = await getVideoLink(video.id);
        }catch(e){
          //snackbar.showMessage(e.message);
          return;
        }
        
        setVideoUrl(videoInfo.link);
        setVideoPreview(videoInfo.preview);
      };
  
      getVideoIdWithSlug();
      goPapers();
    }, [slug, categories, snackbar]);

    // TODO : remettre a plat les noms des typeOfContent surtout les scientific paper
    let infographics = linkedMedias.filter(media => media.typeOfContent?.toLowerCase() === "infographics").slice(0, 8);
    let scientificViewLimit = [
      ...linkedMedias.filter(media => media.typeOfContent?.toLowerCase() === "scientific  paper"),
      ...orderBy(papers,x => x.productionYear,"desc")].slice(0, 6);
    const videos = linkedMedias.filter(media => media.typeOfContent?.toLowerCase() === "videos");

    const suffixTitle = useSuffixTitle();
    const suffixDescription = useSuffixDescription();

    return <div className={classes.root}>

      <Helmet>
        {video?.title && [<meta property="og:title" content={video.title} />,<title>{suffixTitle(video.title)}</title>]}
        {video?.description && [<meta property="og:description" content={stripHtml(video.description)} />,<meta name="description" content={suffixDescription(stripHtml(video.description))} />]}
        {video?.preview && <meta property="og:image" content={video.preview} />}
      </Helmet>

        {videoUrl ? (
          <Video
            video={videoUrl}
            poster={videoPreview}
            bigCenteredMode={true}
            autoplay={true}
            muted={false}
            style={videoStyle}
          />
        ) : (
          <div className={classes.placeholder}></div>
        )}

        <Container maxWidth={false} className={classes.container}>
            {video &&
                <Box my={5} mx={2}>
                    <VideoInformation
                        title={video.title}
                        duration={video.duration}
                        img={video.preview}
                        productionYear={video.productionYear? video.productionYear : undefined}
                        language="English"
                        keywords={video.keywords}
                        description={video.description}
                        providerName={video.providerName}
                        providerNameSlug={video.providerNameSlug}
                    />
                </Box>
            }
            <Box flexGrow={1} my={6}>
                <Typography component="h3" variant="h4" className={classes.exploreTitle}>
                    <CleverFormattedMessage id="explore" />
                </Typography>
            </Box>

            <SubTitle text="related_infographics" path="/infographics" />

            <Grid container spacing={3}>
               {infographics.map((infographic, i) => 
                    <Grid key={i} item xs={6} md={3}> 
                        <SimpleCard 
                            style={simpleCardInfographicsNewStyle}
                            title={infographic.productionYear ? infographic.title + " (" + infographic.productionYear + ")" : infographic.title}
                            category={infographic.providerName ? infographic.providerName + ' - ' + infographic.typeOfContent : infographic.typeOfContent + ' '}
                            type="pdf"
                            img={infographic.preview}
                            path={"/infographics/" + infographic.slug}
                        />
                    </Grid>
                )}
            </Grid>

            <SubTitle text="related_scientific_papers" path="/scientific-papers" />

            <Grid container spacing={3}>
                {scientificViewLimit.map((scientificPaper, i) =>
                    <Grid item xs={12} md={6} key={i}>
                        <a href={scientificPaper.content} key={scientificPaper.content} rel="noopener noreferrer" target="_blank">
                            <PaperCard
                                title={scientificPaper.title}
                                category="Article"
                                link="PDF"
                                date={/*TODO: maybe do better with dates! */ (scientificPaper.productionYear || undefineText).toString()}
                                source="core.ac.uk"
                                description={scientificPaper.description}
                                showMore={true}
                            />
                        </a>
                    </Grid>
                )}
            </Grid>

            <SubTitle text="similar_videos" />
            
            <Grid container spacing={3} className={classes.similarVideos}>
              {videos.slice(0, number_of_related_videos).map((video, i) => (
                <Grid key={i} item xs={6} md={3}>
                  <SimpleCard
                    title={video.productionYear ? video.title + " (" + video.productionYear + ")" : video.title}
                    duration={video.duration}
                    img={video.preview+"?size=medium"}
                    category={video.providerName ? video.providerName : undefined}
                    type="video"
                    path={'/vod/' + video.slug}
                    style={simpleCardNewStyle}
                  />
                </Grid>
              ))}
            </Grid>

        </Container>

        <ResponsiveWithSlugReviveAd slug={"vod"}/>
    </div>
    ;
}
