import React, {useState, useRef} from 'react';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {CleverFormattedMessage, HeaderImage, videokubeUtils} from 'videokube-front-library';
import { Container,Box, Button, TextField, Typography} from '@material-ui/core';
import {useSelector} from "react-redux";
import {IFile} from "videokube-front-library/dist/types";
import {useIntl} from "react-intl";
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import { AppState } from '../../store';
import { api, utils } from 'videokube-front-library';
import { responsiveHeaderImage } from '../../theme';
import ReCAPTCHA from "react-google-recaptcha";
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useHistory } from 'react-router-dom';

const { sendMail } = api;
const { sleep } = utils;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: '20px 0px'
    },
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
    container: {
        paddingLeft: "50px",
        paddingRight: "50px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        }
    },
    input: {
        width: '100%',
        margin: '10px 0px 10px 0px'
    },
    buttonForm: {
        color: 'white'
    },
    contact_us:{
        justifyContent: "space-evenly",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "flex-start",
        }
    }
}));

export function Contact() {
    const classes = useStyles();
    const theme = useTheme();
    const files = useSelector<AppState, IFile[]>((state) => state.app.files);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [mail, setMail] = useState<boolean>(false);
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const { formatMessage } = useIntl();
    const snackbar = useSnackbar();
    const history = useHistory();
    const headerImage = files.find((file) => file.name === "contact_header");

    const contactTitle = formatMessage({id: 'contact_title'});
    const nameLabel = formatMessage({id: 'contact_label_name'});
    const emailLabel = formatMessage({id: 'contact_label_email'});
    const contentLabel = formatMessage({id: 'contact_label_content'});
    const unableToPostContactLabel = formatMessage({id: 'contact_unable_to_post_contact'});
    const contactEmailSentLabel = formatMessage({id: 'contact_email_sent'});

    const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();

        const recaptcha =  await recaptchaRef?.current?.executeAsync();

        if(!recaptcha){
            return snackbar.showMessage(unableToPostContactLabel);
        }
        
        const toSubmit = {
            captcha: recaptcha,
            name,
            email,
            content,
        };

        type toSubmitKey = keyof typeof toSubmit;
        const requiredFields: {key: toSubmitKey,label: string}[] = [
            {key:'name', label: nameLabel},
            {key:'email', label: emailLabel},
            {key:'content', label: contentLabel}
        ];

        for(let aRequiredField of requiredFields){
            if(!toSubmit[aRequiredField.key]){
                snackbar.showMessage(`The field '${aRequiredField.label}' is required`);
                return;
            }
            if(aRequiredField.key === 'email'){
                let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!regEmail.test(email)) {
                    snackbar.showMessage(`Invalid Email Address`);
                    return;
                }
            }
        }

        let resultRaw = await sendMail(content, name, email, recaptcha);

        if(resultRaw.success){
            snackbar.showMessage(contactEmailSentLabel);
            await sleep(3000);
            history.push('/');
            return;
        }
        setMail(false);
    };

    if(!videokubeUtils.getCaptchaSiteKey()){
        return <div><CleverFormattedMessage id="captcha_key_error"/></div>;
    }

    return <div>
    {headerImage && (
        <HeaderImage title={contactTitle} style={responsiveHeaderImage(theme)} img={headerImage.file+"?size=original"}/>
    )}
    {!headerImage && (
        <HeaderImage title={contactTitle} style={responsiveHeaderImage(theme)}/>
    )}

    <Container maxWidth="lg" className={classes.container}>
        <Box>
            <Grid className={classes.title} container spacing={2} justify="flex-end">
                <Grid item xs={12} md={11} lg={11}>
                    <Typography component="h5" variant="subtitle2">
                        <CleverFormattedMessage id="contact_send_message" />
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={classes.root} spacing={2} justify="center">
                <Grid item xs={12} md={10} lg={8}>
                    <form
                        noValidate
                        autoComplete="off"
                        onSubmit={onSubmit}
                    >
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                id="standard-basic"
                                label={nameLabel}
                                onChange={(e) => setName(e.target.value)}
                                variant="outlined" 
                                className={classes.input}
                                required
                            />
                            
                            <TextField 
                                id="standard-basic"
                                label={emailLabel}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                className={classes.input}
                                required
                            />
                        </Grid>
                            
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField 
                                id="standard-basic"
                                label={contentLabel}
                                onChange={(e) => setContent(e.target.value)}
                                variant="outlined" 
                                className={classes.input}
                                multiline
                                rows={5}
                                required
                            />
                        </Grid>

                        <Grid justify="flex-end" container direction="row">
                            <Grid item xs={12} md={4} lg={3}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={videokubeUtils.getCaptchaSiteKey()}
                            />
                                <Button type="submit" className={classes.buttonForm} color="primary" fullWidth={true} variant="contained" disabled={mail}>
                                    <CleverFormattedMessage id="contact_sent_button"/>
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Box>

        <Box>
            <Grid className={classes.title} container spacing={2} justify="flex-end">
                <Grid item xs={12} md={11} lg={11}>
                    <Typography component="h5" variant="subtitle2">
                        <CleverFormattedMessage id="or_contact_us" />
                    </Typography>
                </Grid>
            </Grid>

            <Grid  className={classes.contact_us} spacing={2} container direction="row">
                <Grid item>
                    <RoomIcon fontSize="large" />
                    <CleverFormattedMessage id="contact_address" />
                </Grid>

                <Grid item>
                    <EmailIcon fontSize="large" />
                    <CleverFormattedMessage id="contact_email" />
                </Grid>
            </Grid>
        </Box>
      </Container>
    </div>;
}
