import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CleverFormattedMessage, FBButton, GoogleButton, useLibWrapper, videokubeUtils } from 'videokube-front-library';
import { Box, TextField, Button } from '@material-ui/core';
import { useSelector } from "react-redux";
import { AppState } from '../../store';
import { IFile, JwtPayload } from "videokube-front-library/dist/types";
import { useIntl } from "react-intl";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//TODO: why ever use Link from material ui...
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import { socialNetworkLink } from '../../common';
import ReCAPTCHA from 'react-google-recaptcha';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    '& a': {
      textDecoration: 'underline',
      fontWeight: 'bold'
    }
  },
  paper: {
    //marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  social: {
    width: '100%',
    //margin: 'auto',
    display: 'contents',

  },
  logo: {
    margin: 0,
    maxWidth: 170,
    maxHeight: 170,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 120
    },
  },
  submitButton: {
    padding: '10px 15px'
  },
  socialButton: {
    width: '100%',
  },
  icon: {
    fontSize: '3.3rem',
    margin: '0px 5px'
  },
  link: {
    color: theme.palette.primary.main,
  },
  text: {
    color: theme.palette.primary.main,
  },
  forgetPassword: {
    fontSize: '12px',
    color: theme.palette.primary.main,
    letterSpacing: "0px",
    textTransform: 'capitalize',
  }
}));

export function LoginPage() {
  const classes = useStyles();
  const files = useSelector<AppState, IFile[]>((state) => state.app.files);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { formatMessage } = useIntl();

  const usernameLabel = formatMessage({ id: 'login_label_username' });
  const passwordLabel = formatMessage({ id: 'login_label_password' });
  const buttonSoacialLoginGoogle = formatMessage({ id: 'button_social_login_google' });
  const buttonSoacialLoginFacebook = formatMessage({ id: 'button_social_login_facebook' });
  const [forgetPassword, setForgetPassword] = useState<boolean>(false);
  const needUsername = formatMessage({ id: "need_email" });
  const applyLink = formatMessage({ id: 'login_apply_link' });
  const apply = formatMessage({ id: 'login_apply' });
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const userInfo = useLibWrapper();
  const history = useHistory();

  const googleAppId = videokubeUtils.getGoogleAppId();

  if (googleAppId === undefined) {
    return (<div></div>)
  }

  const continueAfterLogin = async (jwtPayload: JwtPayload) => {
    dispatch({ type: 'SET_JWT_PAYLOAD', data: jwtPayload });
    snackbar.showMessage(formatMessage({ id: "login_success" }));
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/subscribe" ||
      window.location.pathname === "/thank-you"
    ) {
      history.push("/");
    }
  }

  const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    await userInfo.login(username, password);
  };

  const login = (jwt: JwtPayload | undefined): Promise<void> => {
    return continueAfterLogin(jwt as JwtPayload);
  };

  const logo = files.find(file => file.name === "logo");
  if (!logo) {
    return (<div>Logo not found</div>)
  }

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('socialLoginSkip', 'true');
  };

  const sendNewPassword = async () => {
    if (!username) {
      return snackbar.showMessage(needUsername);
    }
    const captchaError = formatMessage({ id: 'captcha_error' });

    const recaptcha = await recaptchaRef?.current?.executeAsync();

    if (!recaptcha) {
      return snackbar.showMessage(captchaError);
    }
    await userInfo.forgetPassword(username, recaptcha);
    handleClose();
    return;
  }

  return <Dialog
    aria-labelledby="customized-dialog-title"
    open={open}
  >
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src={logo.file} alt="Logo" />

        <Typography variant="h6"><CleverFormattedMessage id="login_subtitle" /></Typography>
        {forgetPassword && (
          <React.Fragment>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={usernameLabel}
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={videokubeUtils.getCaptchaSiteKey()}
            />
            <Button fullWidth
              variant="contained"
              color="primary"
              className={classes.submitButton} onClick={() => sendNewPassword()}>
              <CleverFormattedMessage id="login_link_forget_password" />
            </Button>
          </React.Fragment>
        )}
        {!forgetPassword && (
          <React.Fragment>
            <form className={classes.form} noValidate onSubmit={onSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={usernameLabel}
                autoFocus
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={passwordLabel}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />

              <Grid container>
                <Grid item xs>
                  <Button color="primary" className={classes.forgetPassword} onClick={() => setForgetPassword(!forgetPassword)}>
                    <CleverFormattedMessage id="login_link_forget_password" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                  >
                    <CleverFormattedMessage id="login_submit_button" />
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Box mt={2}>
              <Typography variant="h6">OR</Typography>
            </Box>
            <Box mt={2} className={classes.socialButton}>
              <FBButton message={buttonSoacialLoginFacebook} callback={login} scope="email" />
            </Box>
            <Box mt={2} className={classes.socialButton}>
              <GoogleButton message={buttonSoacialLoginGoogle} callback={login} apiID={googleAppId} />
            </Box>
            <Box mt={2}>
              <Grid container justify="center">
                <Grid item>
                  <RouterLink className={classes.link} to="/register">{applyLink}</RouterLink> {apply}
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        )}

        <Box>
          <Button className={classes.link} onClick={handleClose}>
            <CleverFormattedMessage id="skip_text" />
          </Button>
        </Box>
        <Box mt={2}>
          <Grid container justify="center">
            <Grid item>
              <CleverFormattedMessage id="login_follow_us" />
            </Grid>
          </Grid>
        </Box>

        <Box >
          <Link href={socialNetworkLink.instagram} variant="body2">
            <InstagramIcon className={classes.icon} />
          </Link>
          <Link href={socialNetworkLink.twitter} variant="body2">
            <TwitterIcon className={classes.icon} />
          </Link>
          <Link href={socialNetworkLink.facebook} variant="body2">
            <FacebookIcon className={classes.icon} />
          </Link>
          <Link href={socialNetworkLink.youtube} variant="body2">
            <YouTubeIcon className={classes.icon} />
          </Link>
        </Box>
      </div>
    </Container>

  </Dialog>;
}
