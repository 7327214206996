import { createMuiTheme, responsiveFontSizes, Theme } from '@material-ui/core/styles';
import 'typeface-montserrat';
import { InterfaceConfiguration } from 'videokube-front-library/dist/types';

const interfaceConfigurationDefault = {
    id: 1,
    status: "published",
    primary_color: '#FF9800',
    secondary_color: "#FFFFFF",
    background_color: '#000000',
    number_of_items_per_columns: 4,
    number_items_in_category_preview: 4,
    number_of_related_videos: 8,
    video_sorting: "string",
}

export const newSubtitleStyle = {
    root: {
        width: "100%",
        margin: "8.5px 0px 10px 0px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "36px",
        lineHeight: "44px",
        letterSpacing: "3.6px",
    }
}

//export const getTheme = (interfaceConfigurations:InterfaceConfiguration) => {
export const getTheme = (interfaceConfiguration:InterfaceConfiguration) => {
    return responsiveFontSizes(createMuiTheme({
        palette: {
            primary: {
                main: interfaceConfiguration.primary_color
            },
            secondary: {
                main: interfaceConfiguration.secondary_color
            }
        },
        typography: {
            fontFamily: '"Montserrat", cursive',
            h6: {
                fontSize: '14px',
                fontWeight: 'bold',
                color: interfaceConfiguration.primary_color,
                lineHeight: '1',
                letterSpacing: "0.7px",
            },
            body2: {
                fontSize: '12px',
                color: '#555555',
                letterSpacing: "0px",
            },
        },
        overrides: {
            MuiCard: {
                root: {
                    overflow: 'visible'
                }
            },
            MuiButton: {
                root: {
                    borderRadius: '0px',
                    padding: '15px 25px',
                    '&:hover': {
                        backgroundColor: '!important'
                    }
                }
            },
            MuiCardActionArea: {
                focusHighlight: {
                    backgroundColor: 'white'
                }
            },
            MuiTypography: {
                // Title
                h1: {
                    fontSize: '4em'
                },
                // Title specific page
                h2: {
                    fontSize: '3em'
                },
                // Title section
                h3: {
                    fontSize: '2.6em'
                },
                // Subtitle home
                h4: {
                    fontSize: '1.75rem',
                    marginBottom: '2rem'
                },
                // Title big card
                h5: {
                    fontSize: '1.5em',
                    lineHeight: '1'
                },
                // Title medium card
                h6: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: interfaceConfiguration.primary_color,
                    lineHeight: '1',
                    letterSpacing: "0.7px",
                    textTransform: "capitalize",
                },
                body1: {
                    fontSize: '1em',
                    color: '#555555'
                },
                body2: {
                    fontSize: '12px',
                    color: '#555555',
                    letterSpacing: "0px",
                },
                // Link
                subtitle1: {
                    fontSize: '0.9em',
                    color: interfaceConfiguration.primary_color,  
                },
                // Bold
                subtitle2: {
                    fontSize: '0.75em',
                    color: interfaceConfiguration.primary_color,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    lineHeight: '1',
                    letterSpacing: "0.5px",
                },
                // Duration
                overline: {
                    fontSize: '0.91em',
                    color: '#999999',
                    lineHeight: '1'
                },
                // Type
                caption: {
                    fontSize: '0.75em',
                    opacity: '0.9',
                    color: '#999999'
                }
            }
        },
    }));
}

export const DefaultTheme = getTheme(interfaceConfigurationDefault);

export const responsiveHeaderImage = (theme:Theme) => ({
    root: {
        flexGrow: 1,
        backgroundSize: "cover",
        backgroundPosition: "center",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        position: "relative",
        backgroundRepeat: "no-repeat",
        backgroundColor: "",
        height: "225px",
        [theme.breakpoints.down("sm")]: {
            height: "125px",
        },
        [theme.breakpoints.up("xl")]: {
            height: "325px",
        }
    }
});

export const seeAllStyleEndBlock = {
    seeAll: {
        textTransform: "uppercase",
        letterSpacing: "1px",
        color: "#1C4A4E",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        fontSize: "10px",
        margin: "0px 0px 0px 0px",
    }
}

export const simpleCardNewStyle = {
    img: {
        borderRadius: "5px",
        objectFit: 'cover',
        objectPosition: 'top',
        position: 'absolute',
        top: '0',
    },
    play: {
      position: "absolute",
      color: "white",
      fontSize: "5rem",
      opacity: 0
    },
    link: {
        textDecoration: 'none',
        color: '#1C4A4E',
    },
    lazyHolder: {
        width: "100%",
        background: "#f8f8f8",
        paddingTop: '56%',
        borderRadius: "5px",
    },
};

export const simpleCardInfographicsNewStyle = {
    img: {
        borderRadius: "20px",
        objectFit: 'cover',
        objectPosition: 'top',
        height: '100%',
        position: 'absolute',
        top: '0',
    },
    svgIcon: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "left",
        paddingTop: "56%",
        "& svg": {
          opacity: 100,
          transition: "opacity 0.25s ease-in"
        }
    },
    link: {
        textDecoration: 'none',
        color: '#1C4A4E',
    },
    lazyHolder: {
        width: "100%",
        background: "#f8f8f8",
        paddingTop: '56%',
        position: 'absolute',
        borderRadius: "20px",
    }
};

export const infographicsNewStyle = {
    img: {
        borderRadius: "20px",
        objectFit: 'cover',
        objectPosition: 'top',
    },
};

export const navbarStyle = (theme:Theme) => ({
    logo: {
        height: 70,
    },
    button: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: "#009D96"
      }
    },
    buttonRight: {
      padding: '8px',
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: "#009D96",
        color: theme.palette.secondary.main,
      }
    },
    link: {
      color: theme.palette.primary.main,
      margin: theme.spacing(2),
      zIndex: 1400,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "5px",
        zIndex: 1200,
      },
      '&:hover': {
        color:  "#009D96",
      }
    },
    activeButton: {
      color: "#009D96"
    },
    ...searchBar(theme)
}
);

export const searchBar = (theme:Theme) => ({
    searchBar: {
        padding: "6px 8px",
        width: '240px',
        color: theme.palette.primary.main,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding : "0px 0px 8px 0px",
        },
        '& input': {
          backgroundColor: theme.palette.secondary.main,
          height: 0,
          borderRadius: "10px",
          color: theme.palette.primary.main,
        },
        '& fieldset': {
          borderRadius: "10px"
        }
    }}
)

export const videoStyle = {
    maxHeight: "calc(100vh - 74px)",
    paddingTop: "0",
    position: "relative",
    height: "auto",
};

export const simpleCardStyleTopics = {
    img: {
        maxHeight: "250px",
    },
    link: {
        textDecoration: 'none',
        color: '#1C4A4E',
    },
    lazyHolder: {
    },
};

export const videoInformationTV = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        //margin: "auto",
        boxShadow: "none",
        [theme.breakpoints.down("sm")]: {
          display: "table"
        }
      },
      titleDate:{
        display: "flex",
      },
      title: {
        marginTop: "10px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.8em"
        }
      },
      videoInformationImg:{
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
        }
      },
      columnTwo: {
        paddingLeft: "20px",
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "0px",
          maxWidth: "100%",
          //display: "table",
          "& p": {
            fontSize: "0.6em"
          }
        }
      },
})
