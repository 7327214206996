import {combineReducers} from 'redux';
import {store, videokubeUtils} from 'videokube-front-library';
import {createSelector} from 'reselect'

export const defaultState = videokubeUtils.getDefaultState();
type State = typeof defaultState;

const finalReducer = combineReducers({
  app: store.appRecucer,
});

export type AppState = { app: State };
export { finalReducer };

const getCategories = (state: AppState) => state.app.categories;

export const computedMedias = createSelector([getCategories], (categories) =>
  categories.flatMap((aCategory) => aCategory.medias),
);

export const computedInfographics = createSelector([computedMedias], (medias) =>
  medias.filter(media => media.typeOfContent?.toLowerCase() === "infographics")
);