import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
import { utils } from 'videokube-front-library';
import "videokube-front-library/dist/index.css";

render(<App />, document.getElementById('root'));

const reactAppGTM = utils.flexibleGetEnv("REACT_APP_GTM_TAG")
if (reactAppGTM){
    const tagManagerArgs = {
        gtmId: reactAppGTM
    }

    TagManager.initialize(tagManagerArgs)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
