import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { SimpleCard, Video, VideoInformation, SubTitle, videokubeUtils } from 'videokube-front-library';
import { Container, Box } from '@material-ui/core';
import {useSelector} from "react-redux";
import { AppState } from '../../store';
import { Category, VideoLink, Media } from 'videokube-front-library/dist/types';
//import { computedMedias } from '../../store';
import {simpleCardStyleTopics, videoStyle, simpleCardNewStyle, videoInformationTV} from "../../theme";
import { ResponsiveWithSlugReviveAd } from '../../components/ReviveAd';
import { api } from 'videokube-front-library';
const { getMedias } = api;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
    description: {
        lineHeight: '24px',
        marginTop: '40px'
    },
    verticalBar: {
        borderLeft: '2px solid #999999',
        marginRight: '10px',
        marginLeft: '10px',
    },
    container: {
        paddingLeft: "50px",
        paddingRight: "50px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        }
    },
    placeholder: {
      background: '#f8f8f8',
      height: 'calc(100vh - 74px)',
      [theme.breakpoints.down('xl')]: {
        height: '56.25vw',
        width: '100vw'
      }
    }
}));

export function Tv() {
    //TODO: get all highlighted medias (...)
    //Cache all medias
    //const medias = useSelector(computedMedias);
    let channelUrl = "";
    let channelPreview = "";
    const classes = useStyles();
    const theme = useTheme();

    const categories = useSelector<AppState,Category[]>(state => state.app.categories);
    const [highlightedMedias,setHighlightedMedias] = useState<Media[]>([]);

    useEffect(() => {
      const highlightedMedias = async () => {
        let linkedMedias = await getMedias({highlight: 1, limit: 1000, live: 0});
        setHighlightedMedias(linkedMedias);
      };
      highlightedMedias();
    }, []);

    const tvVideoLink = useSelector<AppState,VideoLink>(
        state => state.app.tvVideoLink,
      );
    const channel = useSelector<AppState, Media | null>(
        (state) => state.app.mainChannel,
    ) as Media;

    if (tvVideoLink) {
        channelUrl = tvVideoLink.link;
    }

    const currentProgram = videokubeUtils.getCurrentProgram(channel);
    const nextProgram = videokubeUtils.getNextProgram(channel);

    return <div className={classes.root}>
        {channelUrl ? (
          <Video
            key={channelUrl}
            video={channelUrl}
            poster={channelPreview}
            bigCenteredMode={true}
            autoplay={true}
            muted={false}
            style={videoStyle}
          />
        ) : (
          <div className={classes.placeholder}></div>
        )}
        <Container maxWidth={false} className={classes.container}>
            <Box display="flex" mt={2} mb={7}>
                {channel && currentProgram && (
                    <Grid container item xs={12} md={6}>
                        <VideoInformation
                            title={currentProgram.name}
                            img={currentProgram.thumbnail ? currentProgram.thumbnail : channel.preview }
                            startAt={new Date(currentProgram.start)}
                            language="English"
                            description={currentProgram.description}
                            showMore={true}
                            titlePlaying='now_playing'
                            style={videoInformationTV(theme)}
                        />
                    </Grid>
                )}

                {channel && nextProgram && (
                    <React.Fragment>
                        <div className={classes.verticalBar}></div>

                        <Grid container item xs={12} md={6}>
                            <VideoInformation
                                title={nextProgram.name}
                                img={nextProgram.thumbnail ? nextProgram.thumbnail : channel.preview }
                                startAt={new Date(nextProgram.start)}
                                language="English"
                                description={nextProgram.description}
                                showMore={true}
                                titlePlaying='coming_up'
                                style={videoInformationTV(theme)}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Box>
        </Container>

        <ResponsiveWithSlugReviveAd slug={"tv"}/>

        <Container maxWidth={false} className={classes.container}>
            <SubTitle text="trending_now" />

            <Grid container spacing={3}>
                {highlightedMedias.map((highligtContent, i) =>
                    <Grid key={i} item xs={6} md={3}> 
                        <SimpleCard
                            title={ highligtContent.productionYear ? highligtContent.title + ' (' + highligtContent.productionYear + ')' : highligtContent.title}
                            category={highligtContent.providerName ? highligtContent.providerName : undefined}
                            img={highligtContent.preview}
                            duration={highligtContent.duration ? highligtContent.duration : undefined}
                            type={highligtContent.format ? highligtContent.format : "" }
                            path={"/vod/" + highligtContent.slug}
                            style={simpleCardNewStyle}
                        />
                    </Grid>
                )}
            </Grid>

            <SubTitle text="topics" />

            <Grid container spacing={3}>
                {categories.map((category, i) => 
                    <Grid key={i} item xs={12} md={4}> 
                        <SimpleCard
                            style={simpleCardStyleTopics}
                            title={category.name.toUpperCase()}
                            type="category"
                            //videos={collection.videos}
                            img={category.preview}
                            path={"/category/" + category.slug}
                        />
                    </Grid>
                )}
            </Grid>
        </Container>
    </div>
    ;
}
