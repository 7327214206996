import React from 'react';
import { Box } from '@material-ui/core';

interface LoaderProps {
  height?: string;
  size?: string;
}

export function Loader(props:LoaderProps) {

  let height = props.height || '100vh';
  let size = props.size || '100px'

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={1}
      m={1}
      css={{ height: height, padding: '0', margin: '0' }}
    >
      <div className="loaderContainer" style={{height : size, width : size}}>
        <div className="loaderSpec loaderjaune"></div>
        <div className="loaderSpec loaderblue"></div>
        <div className="loaderSpec loadervert"></div>
      </div>
    </Box>
  );
}
