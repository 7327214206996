import React, {useState, useEffect} from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import { HeaderImage, UniversityClasses as UniversityClassesComponent, SubTitle, CleverFormattedMessage } from 'videokube-front-library';
import {useSelector} from "react-redux";
import { AppState } from '../../store';
import {IFile, University} from 'videokube-front-library/dist/types';
import { responsiveHeaderImage } from '../../theme';
import { api } from 'videokube-front-library';

const { getUniversities } = api;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
    description: {
        lineHeight: '24px',
        marginTop: '40px'
    },
    blockUniversity: {
    },
    container: {
        paddingLeft: "50px",
        paddingRight: "50px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        }
    },
    clickable: {
        cursor: 'pointer'
    }
}));

export function UniversityClasses() {
    const classes = useStyles();
    const theme = useTheme();
    const [universities, setUniversities] = useState<University[]|null>(null);

    const files = useSelector<AppState, IFile[]>((state) => state.app.files);
    let universityClasses_header = files.find(
        (file) => file.name === 'universityClasses_header',
      );

    useEffect(() => {
        // Doing the universities loading after the missing check to avoid heavy load
        getUniversities().then((universities: University[]) => setUniversities(universities));
    },[]);
    
    if (!universityClasses_header) {
      return <div>missing universityClasses_header</div>;
    }
    
    const redirectToUniversity = (university: University): any => {
        window.open(university.website);
    };

    return <div className={classes.root}>
        <HeaderImage style={responsiveHeaderImage(theme)} title="University Classes" img={universityClasses_header.file} back={true}/>

        <Container maxWidth={false} className={classes.container}>
            <Typography component={'span'} variant="body2" className={classes.description}>
                <CleverFormattedMessage id="university_classes_description" />
            </Typography>

            <SubTitle text="trending_university_classes" />
            <Grid container spacing={3} >
                {universities !== null ? universities.map((university: University, index: number) =>
                    <Grid className={classes.clickable} key={index} item xs={12} md={12} onClick={() => redirectToUniversity(university)}>
                        <UniversityClassesComponent
                            title={university.courses_title}
                            category={university.name}
                            link="Click to open" // What to put right here ??
                            date={university.date || ''}
                            source={university.website}
                            description={university.courses_description || 'No description found'}
                            showMore={true}
                            cost={university.cost}
                            coursesLevel={university.courses_level}
                            coursesLength={university.courses_length}
                            coursesType={university.courses_type}
                        />
                    </Grid>
                ) : []}
            </Grid>
        </Container>
    </div>
    ;
}
