import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { HeaderImage, SubTitle, CleverFormattedMessage, videokubeUtils } from 'videokube-front-library';
import { Container, Button, TextField, Grid, Box } from '@material-ui/core';
//import { FormControlLabel, Checkbox } from '@material-ui/core';
import {useSelector} from "react-redux";
import { AppState } from '../../store';
import { IFile } from 'videokube-front-library/dist/types';
import { responsiveHeaderImage } from '../../theme';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import { CheckoutForm } from './CheckoutForm';
// import CreditCardIcon from '@material-ui/icons/CreditCard';
// import { Link } from 'react-router-dom';
import { PayPalButton } from "react-paypal-button-v2";
import NumberFormat from 'react-number-format';
//import { FormatAlignCenter, Translate } from '@material-ui/icons';
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
    buttons: {
        textAlign: 'center'
    },
    button: {
        margin: '10px 10px'
    },
    container: {
        
    },
    description: {
    },
    divider: {
        marginTop: '40px',
        height: '2px',
        color: theme.palette.primary.main,
    },
    input: {
        width: '100%',
        margin: '10px 10px'
    },
    otherInput: {
        //width: '100%',
        margin: '10px 0px 10px 0px',
        width: '120px',
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        textAlign: 'center',
        '& input':{
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
        '& label': {
            color: theme.palette.primary.main,
            //transform: 'translate(1px, 1px)',
            //margin: 'inherit'
        },
        '& .MuiInputLabel-outlined': {
            color: theme.palette.primary.main,
            transform: 'translate(1px, 10px) scale(1);',
            //margin: 'inherit'
        },
        '& .MuiInputLabel-shrink': {
            color: theme.palette.primary.main,
            transform: 'translate(14px, -6px) scale(0.75);',
            //margin: 'inherit'
        },
        '& input:valid + fieldset': {
            borderColor: theme.palette.primary.main,
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
          borderLeftWidth: 6,
          padding: '4px !important', // override inline-style
        },
    },
    autocomplete: {
        margin: '10px 0px 10px 0px'
      },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    card: {
        textAlign: 'center',
        padding: '20px',
        border: '2px solid green',
        margin: '10px 0px'
    },
    cardTitle: {
        fontWeight: 'bold',
        margin: '20px 0px'
    },
    img: {
        maxWidth: '100%'
    },
    containedSecondary: {
        color: '#009D96'
    },
    alignLeft: {
        textAlign: 'left'
    },
    alignCenter: {
        textAlign: 'center'
    },
    textArea:{
        color: theme.palette.primary.main,
    }
}));

const ColorButton = withStyles((theme) => ({
    containedPrimary: {
        '&:hover': {
            backgroundColor: '#009D96',
        },
    },
    containedSecondary: {
        backgroundColor: '#009D96',
        color: '#FFFFFF',
        
    },
}))(Button);

const amounts = [20, 50, 100, 500, 1000, 2500, 5000, 10000];

// const donationTypes = [
//     {
//         id: 1,
//         name: 'Product / Item',
//         slug: 'product-item',
//         checked: false
//     },
//     {
//         id: 2,
//         name: 'Service',
//         slug: 'service',
//         checked: false
//     },
//     {
//         id: 3,
//         name: 'Other',
//         slug: 'other',
//         checked: false
//     }
// ];

interface State {
    numberformat: string;
  }

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
  
function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
            onChange({
                target: {
                name: props.name,
                value: values.value,
                },
            });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
            allowNegative={false}
            allowLeadingZeros={false}
        />
    );
}
export function Donate() {
    const files = useSelector<AppState, IFile[]>((state) => state.app.files);
    const classes = useStyles();
    const theme = useTheme();
    const [formAmount, setFormAmount] = useState<number|string>();
    const { formatMessage } = useIntl();
    const enterAmount = formatMessage({id: 'enter_amount'});
    /*const [donationType, setDonationType] = React.useState(donationTypes);
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [organization, setOrganization] = useState('');
    const [email, setEmail] = useState('');
    const [streetAddressOne, setStreetAddressOne] = useState('');
    const [streetAddressTwo, setStreetAddressTwo] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');*/

    const [values, setValues] = useState<State>({
        numberformat: '',
    });

    let topics_header = files.find(
        (file) => file.name === 'donate_header',
    );

    let gem_logo = files.find(
        (file) => file.name === 'logo',
    );

    let gema_logo = files.find(
        (file) => file.name === 'gema_logo',
    );

    if (!topics_header || !gem_logo || !gema_logo) {
        return <div>missing images</div>;
    }

    // const handleChangeDonationType = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setDonationType({ ...donationType, [event.target.name]: event.target.checked });
    // };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value,
        });
        setFormAmount(event.target.value);
      };

    if(!videokubeUtils.getPaypalClientId()){
        return <div><CleverFormattedMessage id="paypal_clientID_error"/></div>;
    }

    return <div className={classes.root}>
        <HeaderImage title="Donate" style={responsiveHeaderImage(theme)} img={topics_header.file+"?size=original"}/>

        <Container maxWidth="lg" className={classes.container}>

            <Box m={3}>
                <Grid container justify="space-evenly">
                    <Grid item xs={6} md={2} xl={2} >
                        <a href="https://gema.mc/" className={classes.alignCenter} target="_blank" rel="noopener noreferrer">
                            <img className={classes.img} src={gema_logo.file} alt="GEMA Logo"/>
                        </a>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <img className={classes.img} src={gem_logo.file} alt="GEM Logo" />
                        <a href="https://gema.mc/" className={classes.alignCenter} target="_blank" rel="noopener noreferrer">
                            <CleverFormattedMessage  id="donate_supported_by_gema"/>
                        </a>
                    </Grid>
                </Grid>
            </Box>

            <Box m={2}>
                <Typography
                    component={'div'}
                    align="left"
                    variant="body2"
                    className={classes.description}
                >
                    <CleverFormattedMessage id="donate_description" />
                </Typography>
            </Box>

            <SubTitle text="donate_select_amount"/>

            <Box mt={2}>
                <Grid container justify="center">
                    <Grid item className={classes.buttons}>
                        {amounts.map((amount, key) =>
                            <ColorButton
                                key={key}
                                className={classes.button}
                                variant="contained"
                                color={formAmount === key ? 'secondary' : 'primary'}
                                onClick={(e) => setFormAmount(amount)}
                            >
                                ${amount}
                            </ColorButton>
                        )}

                        <TextField
                            classes={{root: classes.otherInput }}
                            label={enterAmount}
                            value={values.numberformat}
                            onChange={handleChange}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            InputProps={{
                            inputComponent: NumberFormatCustom as any,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </Box>

            <SubTitle text="donate_billing_information"/>

            {/*<Box>
                <Grid container justify="space-evenly">
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="standard-basic"
                            label="First name"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            variant="outlined" 
                            className={classes.input}
                        />

                        <TextField
                            id="standard-basic"
                            label="Last name"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            variant="outlined" 
                            className={classes.input}
                        />

                        <TextField
                            id="standard-basic"
                            label="Organization"
                            onChange={(e) => setOrganization(e.target.value)}
                            value={organization}
                            variant="outlined" 
                            className={classes.input}
                        />

                        <TextField
                            id="standard-basic"
                            label="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            variant="outlined" 
                            className={classes.input}
                        />

                        <TextField
                            id="standard-basic"
                            label="Street Address"
                            onChange={(e) => setStreetAddressOne(e.target.value)}
                            value={streetAddressOne}
                            variant="outlined" 
                            className={classes.input}
                        />
                    </Grid>

                    <Grid xs={12} md={4}>
                        <TextField
                            id="standard-basic"
                            label="Address line 2"
                            onChange={(e) => setStreetAddressTwo(e.target.value)}
                            value={streetAddressTwo}
                            variant="outlined" 
                            className={classes.input}
                        />

                        <TextField
                            id="standard-basic"
                            label="City"
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            variant="outlined" 
                            className={classes.input}
                        />

                        <Autocomplete
                            id="region-select-demo"
                            options={countries as CountryType[]}
                            classes={{
                                root: classes.autocomplete,
                                option: classes.option,
                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => {
                                if (value) {
                                    setRegion(value.label);
                                }
                            }}
                            inputValue={region}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <span>{countryToFlag(option.code)}</span>
                                    {option.label}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="State"
                                variant="outlined"
                                inputProps={{
                                ...params.inputProps,
                                }}
                            />
                            )}
                        />

                        <TextField
                            id="standard-basic"
                            label="Postal Code"
                            onChange={(e) => setPostalCode(e.target.value)}
                            value={postalCode}
                            variant="outlined" 
                            className={classes.input}
                        />

                        <Autocomplete
                            id="country-select-demo"
                            options={countries as CountryType[]}
                            classes={{ 
                                root: classes.autocomplete,
                                option: classes.option,
                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => {
                                if (value) {
                                    setCountry(value.label);
                                }
                            }}
                            inputValue={country}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <span>{countryToFlag(option.code)}</span>
                                    {option.label}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Country"
                                variant="outlined"
                                inputProps={{
                                ...params.inputProps,
                                }}
                            />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>*/}

            <Box mt={4}>
                <Grid container justify="space-evenly">
                    {formAmount && (
                        <Grid item xs={12} md={4} className={classes.card}>
                            <PayPalButton
                                amount={formAmount}
                                onSuccess={(details: any, data: any) => {
                                    alert("Transaction completed by " + details.payer.name.given_name);
                                }}
                                options={{
                                    clientId: videokubeUtils.getPaypalClientId()
                                }}
                            />
                        </Grid>
                    )}
                    {!formAmount && (
                        <div className={classes.textArea}>
                            <CleverFormattedMessage id = "need_choice_amount"/>
                        </div>
                    )}
                </Grid>
            </Box>

            {/* <Box mt={4}>
                <Grid container justify="space-evenly">
                    <Grid item xs={12} md={4} className={classes.card}>
                        <Typography variant="h5" className={classes.cardTitle}>
                            <CleverFormattedMessage id="donation_by_check"/>
                        </Typography>

                        <Box m={2} className={classes.alignLeft}>
                            <Typography>
                                <CleverFormattedMessage id="donation_description"/>
                            </Typography>
                        </Box>

                        <Link to="gema-donation.pdf" target="_blank" download>
                            <Button variant="contained" color="primary">
                                <CleverFormattedMessage id="download_pdf" />
                            </Button>
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={4} className={classes.card}>
                        <Typography variant="h5" className={classes.cardTitle}>
                            <CleverFormattedMessage id="other_donation_type"/>
                        </Typography>

                        {donationTypes.map((donationType) =>
                            <FormControlLabel
                                control={<Checkbox checked={donationType.checked} 
                                    onChange={handleChangeDonationType} 
                                    name={donationType.slug} 
                                />}
                                label={donationType.name}
                            />
                        )}

                        <TextField
                            id="standard-basic"
                            label="Description / Comments"
                            //onChange={(e) => setFirstName(e.target.value)}
                            variant="outlined" 
                            className={classes.input}
                            multiline
                            rows={3}
                        />
                        
                        <Button variant="contained" color="primary">DONATE</Button>
                    </Grid>
                </Grid>
            </Box> */}

        </Container>
    </div>
    ;
}
