import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//import { useSelector } from "react-redux";
//import { AppState} from '../../store';
import { Media } from "videokube-front-library/dist/types";
import { GenericMediaViewer } from '../../components/GenericMediaViewer';
import { api } from 'videokube-front-library';
import { Loader } from '../../components/Loader';
const { getMedias } = api;

export function ProviderView(){
    const [videos,setVideos] = useState<Array<Media>>([]);
    const [statusPage,setStatusPage] = useState<boolean>(false);
    const [infos,setInfos] = useState<Array<Media>>([]);
    const [providerName, setProviderName] = useState<string>('');

    let { slug } = useParams<{slug:string}>();
    useEffect(() => {
        if(!slug){
            return;
        }
        const getProviderNameSlug = async () => {
            const pmedias = await getMedias({providerName: slug, limit: 1000});
            let mediaVideos = pmedias.filter(media => media.typeOfContent?.toLowerCase() === "videos");
            let mediaInfos = pmedias.filter(infographic => infographic.typeOfContent?.toLowerCase() === "infographics");
            setVideos(mediaVideos);
            setInfos(mediaInfos);
            setStatusPage(true);
            if (pmedias[0] && pmedias[0].providerName){
                setProviderName(pmedias[0].providerName);
            }
        }

        getProviderNameSlug();
    }, [slug]);

    if (!slug) {
        return <div>No tag!</div>;
    }

    if (statusPage && !videos.length && !infos.length) {
        return <div>This tag has no medias!</div>;
    }

    if (!statusPage){
        return (
            <div style={{margin: '20px'}}>
            <Loader height='5vh' size='50px'/>
            </div>
        )
    }
    return <GenericMediaViewer videos={videos} infographics={infos} scientificPapers={[]} title={providerName ? providerName : slug} />
}