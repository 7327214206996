import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { SimpleCard, HeaderImage, SubTitle, CleverFormattedMessage } from 'videokube-front-library';
import { Container, Divider } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import {useSelector} from "react-redux";
import { AppState } from '../../store';
import { Category, IFile, Media } from 'videokube-front-library/dist/types';
import { simpleCardNewStyle, newSubtitleStyle, responsiveHeaderImage } from '../../theme';
import { api } from "videokube-front-library";
const { getMedias } = api;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
    container: {
        paddingLeft: "50px",
        paddingRight: "50px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        }
    },
    description: {
        paddingLeft: "18vw",
        paddingRight: "18vw",
        letterSpacing: "0px",
        color: "#9B9B9B",
        font: '12px Montserrat',
        fontSize: '12px',
    },
    divider: {
        marginTop: '40px',
        height: '2px',
        color: theme.palette.primary.main,
    },
}));

export function Topics() {
    const theme = useTheme();
    const categories = useSelector<AppState,Category[]>(state => state.app.categories);
    const files = useSelector<AppState, IFile[]>((state) => state.app.files);
    const nbItemsPerColumns =
        (useSelector<AppState>(
        (state) => state.app.interfaceConfiguration?.number_of_items_per_columns,
        ) as number) || 4;
    const nbItemsInCategoryPreview =
        (useSelector<AppState>(
        (state) =>
            state.app.interfaceConfiguration?.number_items_in_category_preview,
        ) as number) || 4;
    
    const initialPerCategoriesResultValue = Object.fromEntries(categories.map(x => [x.id, [] as Media[]]));
    const [perCategoriesResult, setPerCategoriesResult] = useState<{[key:string]: Media[]}>(initialPerCategoriesResultValue);

    useEffect(() => {
        const fetchCategoriesMedias = async (category: Category) =>{
            const mediasForCategory = await getMedias({"category": category.slug,"typeOfContent":"videos", "limit": nbItemsInCategoryPreview});
            setPerCategoriesResult(realCurrentValue => {
                return {...realCurrentValue, [category.id]: mediasForCategory}
            });
        }
        categories.forEach(x => fetchCategoriesMedias(x));
    },[categories, nbItemsInCategoryPreview]);

    const calculatedMd = Math.floor(12 / nbItemsPerColumns);

    const classes = useStyles();

    let topics_header = files.find(
        (file) => file.name === 'topics_header',
      );

      if (
        !topics_header
      ) {
        return <div>missing topics_header</div>;
      }

    return <div className={classes.root}>
        <HeaderImage title="Topics" style={responsiveHeaderImage(theme)} img={topics_header.file+"?size=original"}/>

        <Container maxWidth={false} className={classes.container}>
            <Hidden smDown>
                <Typography
                    component={'div'}
                    align="left"
                    variant="body2"
                    className={classes.description}
                >
                    <CleverFormattedMessage id="topics_description" />
                </Typography>
            </Hidden>
            {categories.map((category) =>
                <div key={category.id} >
                    <Divider className={classes.divider}/>
                    <SubTitle
                        style={newSubtitleStyle}
                        text={category.name}
                        path={'/category/' + category.slug}
                        border={false}
                        dontTranslate={true}
                    />
                    
                    <Grid key={category.id} container spacing={2}>
                        {perCategoriesResult[category.id].map((video) =>
                            <Grid key={video.id} item xs={6} md={calculatedMd as any}>
                                <SimpleCard
                                    title={video.productionYear ? video.title + " (" + video.productionYear + ")" : video.title}
                                    duration={video.duration}
                                    img={video.preview+"?size=medium"}
                                    category={video.providerName ? video.providerName : undefined}
                                    type="video"
                                    path={'/vod/' + video.slug}
                                    style={simpleCardNewStyle}
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>
            )}
        </Container>
    </div>
    ;
}