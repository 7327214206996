import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from "@material-ui/core";
import { AppState } from "../store";
import { useSelector } from "react-redux";
import { IFile } from "videokube-front-library/dist/types";
import { CleverFormattedMessage } from 'videokube-front-library';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    logo: {
        marginTop: 10,
        maxWidth: 120,
        maxHeight: 120,
        [theme.breakpoints.down("sm")]: {
          maxWidth: 120
        },
    },
    donateButton: {
        padding: '8px 16px',
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: "#27af9f",
          color: theme.palette.secondary.main,
        },
        margin: "20px"
    },
    socialMedia: {
        display: "flex",
        margin: "20px 0",
        "& > svg": {
            fontSize: "2rem",
            flex: "1"
        }
    },
    spacer: {
        margin: "10px"
    },
    link: {
        textDecoration: 'none',
    }
}));

interface AdvertiseProps {
    spreadWordKey: string;
    thankYouKey: string;
}

export function Advertise(props: AdvertiseProps) {
    const classes = useStyles();

    const files = useSelector<AppState, IFile[]>((state) => state.app.files);

    const logo = files.find(file => file.name === "logo");
    if(!logo) {
      return (<div>Logo not found</div>);
    }

    return <div>
        <Typography component={'div'} variant="body2" className={classes.spacer}>
            <CleverFormattedMessage id={props.spreadWordKey} className={classes.spacer}/>
        </Typography>
        <Typography component={'div'} variant="body2" className={classes.spacer}>
            <CleverFormattedMessage id="follow_social_media" className={classes.spacer}/>
        </Typography>

        <div className={classes.socialMedia}>
            <InstagramIcon/>
            <TwitterIcon/>
            <FacebookIcon/>
            <YouTubeIcon/>
        </div>

        <Typography component={'div'} variant="body2" className={classes.spacer}>
            <CleverFormattedMessage id="support_donate" className={classes.spacer}/>
        </Typography>

        <Link className={classes.link} to="/donate">
            <Button className={classes.donateButton}><CleverFormattedMessage id="donate_title"/></Button>
        </Link>

        <Typography component={'div'} variant="body2" className={classes.spacer}>
            <CleverFormattedMessage id={props.thankYouKey} className={classes.spacer}/>
        </Typography>
        <Typography component={'div'} variant="body2" className={classes.spacer}>
            <CleverFormattedMessage id="gem_team" className={classes.spacer}/>
        </Typography>

        <img className={classes.logo} src={logo.file} alt="Logo" />
        <Typography color="primary" component="h6" variant="overline">
            <CleverFormattedMessage id="logo_baseline_small"/>
        </Typography>
    </div>;
}