import React, { useEffect, useState } from 'react';
import axios from "axios";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { SubTitle, api, CleverFormattedMessage } from 'videokube-front-library';
import { useParams } from 'react-router-dom';
import { Media as IScientificPaper, Nullable } from 'videokube-front-library/dist/types';
import { useSnackbar } from 'material-ui-snackbar-provider';

import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import Viewer, { RenderToolbar, Slot, SpecialZoomLevel, Worker, defaultLayout } from '@phuocng/react-pdf-viewer';
import { Loader } from '../../components/Loader';

const { getScientificPaperWithId, getIdWithSlug } = api;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
    title: {
        fontSize: "2rem",
        marginBottom: "20px"
    },
  }
));

interface ScientificPaperBaseProps{
  local: boolean;
}

function ScientificPaperBase(props: ScientificPaperBaseProps) {
    const classes = useStyles();
    let { slug } = useParams<{slug:string}>();
    const [scientificPaper, setScientificPaper] = useState<IScientificPaper | null>(null);
    const [urlIsOk, setUlrIsOk] = useState<string>('waiting');
    const snackbar = useSnackbar();


    useEffect(() => {
        const getScientificPaper = async () => {
          if (!slug) {
            return;
          }

          let sciPap: Nullable<IScientificPaper> = null;
          try {
            if(!props.local){
              sciPap = await getScientificPaperWithId(parseInt(slug));
            }
            else{
              const listOfMedias = await getIdWithSlug(slug);
              if(listOfMedias.length){
                sciPap = listOfMedias[0];
              }
            }
          } catch(e) {
            snackbar.showMessage(e.message);
            return;
          }
          setScientificPaper(sciPap);
        }

        getScientificPaper();
    }, [snackbar, slug, props.local]);

    useEffect(() => {
      const checkUrl = async () => {
        if (scientificPaper) {
          try {
            const resultRaw = await axios({
              method: 'head',
              url: scientificPaper.content,
            });

            setUlrIsOk('true');
            if (resultRaw.status >= 400){
              setUlrIsOk('false');
            }
          } catch(e) {
            setUlrIsOk('false');
            //snackbar.showMessage(e.message);
            return;
          }
        }

      };
      checkUrl();
    }, [scientificPaper]
    );

    const SimpleToolbar  = (fileUrl:string)  => {
      const renderToolbar = (toolbarSlot:any) => {
        return (
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 1,
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ padding: '0 2px' }}>
                        {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                    </div>
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        marginLeft: 'auto',
                    }}
                >
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.fullScreenButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.downloadButton}
                </div>
                </div>
            </div>
        );
    };
      const layout = (
          isSidebarOpened: boolean,
          container: Slot,
          main: Slot,
          toolbar: RenderToolbar,
          sidebar: Slot,
      ): React.ReactElement => {
          return defaultLayout(
            isSidebarOpened,
            container,
            main,
            toolbar(renderToolbar),
            sidebar,
          )
      };

      return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
          <Viewer
              fileUrl={fileUrl}
              layout={layout}
              defaultScale={SpecialZoomLevel.PageFit}
          />
        </Worker>
      );
  };

    return <div className={classes.root}>
         <Container>
          {(!scientificPaper || urlIsOk === 'waiting') && (
            <Loader height='5vh' size='50px'/>
          )}
          {scientificPaper && urlIsOk === 'true' && (
              <div>
                  <SubTitle text={scientificPaper.title} />
                  {SimpleToolbar(scientificPaper.content)}
              </div>
          )}
          {scientificPaper && urlIsOk === 'false' && (
            <CleverFormattedMessage id="scientific_papers_no_more_available" className={classes.title}/>
          )}

        </Container>
     </div>
     ;
}

export const ScientificPaper = () => {
  return <ScientificPaperBase local={false} />
}

export const GemScientificPaper = () => {
  return <ScientificPaperBase local={true} />
}