import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
//import Grid from '@material-ui/core/Grid';
import { CleverFormattedMessage, Video } from 'videokube-front-library';
import {useSelector} from "react-redux";
import { AppState } from '../store';
import { IFile } from 'videokube-front-library/dist/types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionOne: {
        textAlign: 'center',
        minHeight: 'calc(100vh - 74px)',
        //maxHeight: '100vh',
        position: 'relative',
        zIndex: 2,
        overflow: 'hidden'
    },
    video: {
        minWidth: "100%",
        minHeight: "100%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bottom: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    sectionTwo: {
        minHeight: '80vh',
        textAlign: 'left',
        padding: '20px',
        backgroundSize: 'cover',
        backgroundPosition: 'right'
    },
    logo: {
        width: '45vh',
        marginTop: '5vh',
        [theme.breakpoints.down('sm')]: {
            width: '25vh'
        }
    },
    baseline: {
        marginTop: '10px',
        letterSpacing: '6.6px',
        fontSize: '1em',
        fontWeight: 'bold',
        color: '#009D96',
        font: 'Regular 20px/40px Montserrat'
    },
    title: {
        marginTop: '80px',
        fontSize: '5em',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2em',
            marginTop: '40px',
        },
        [theme.breakpoints.between('sm','lg')]: {
            fontSize: '3em',
            marginTop: '40px',
        }
    },
    button: {
        marginBottom: '180px',
        marginTop: '60px',
        fontWeight: 'bold',
        letterSpacing: '1.2px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '30px',
            marginBottom: '30px'
        }
    },
    sectionText: {
        display: 'flex',
    },
    sectionTextTwo: {
        marginTop: '30px',
        marginBottom: '5vh',
        //maxHeight: '100vh',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
        }
    },
    sectionTextTitle: {
        marginTop: '50px',
        fontWeight: 500,
        fontSize: '2em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.6em',
        }
    }
}));

export function About() {
    const classes = useStyles();

    const files = useSelector<AppState,IFile[]>(state => state.app.files);
    
    let homepage_background_one = files.find(file => file.name === 'homepage_background_one');
    let homepage_background_two = files.find(file => file.name === 'homepage_background_two');
    let home_background_video = files.find(file => file.name === 'home_background_video');
    let logo = files.find(file => file.name === "logo");

    if (!homepage_background_one || !homepage_background_two || !home_background_video || !logo) {
        return <div>Missing homepage_background_one or homepage_background_two or home_background_video or logo</div>;
    }

    return <div>
        <div className={classes.sectionOne}>
            <div className="container">
                <Box className={classes.sectionOne}>
                    <img src={logo.file} className={classes.logo} alt="Logo gem"/>
                    <Typography className={classes.baseline} color="primary" component="h2" variant="overline">
                        <CleverFormattedMessage id="logo_baseline"/>
                    </Typography>
                    <Typography className={classes.title} color="primary" component="h1" variant="h1">
                        <CleverFormattedMessage id="homepage_title"/>
                    </Typography>
                    <Link to={"/tv"}>
                        <Button className={classes.button} variant="contained" color="primary">
                            <CleverFormattedMessage id="button_live"/>
                        </Button>
                    </Link>
                </Box>
            </div>
            <Box className={classes.video}>
                <Video
                    video={home_background_video.file}
                    poster={homepage_background_one.file+"?size=original"}
                    backgroundMode={true}
                />
            </Box>
        </div>

        {/* <Box className={classes.sectionTwo}>
            <Grid className={classes.sectionText} direction="row-reverse" container>
                <Grid className={classes.sectionTextTwo} item >
                    <CleverFormattedMessage id="homepage_section_two"/>
                </Grid>
            </Grid>
        </Box> */}
    </div>
    ;
}
