import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Container, Box, TextField, Button } from '@material-ui/core';
import { HeaderImage, PaperCard, SubTitle, CleverFormattedMessage, api } from 'videokube-front-library';
import {useSelector} from "react-redux";
import { AppState } from '../../store';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { IFile } from 'videokube-front-library/dist/types';
//import { Link } from 'react-router-dom';
import { IScientificPaper } from 'videokube-front-library/dist/types';
import { responsiveHeaderImage, seeAllStyleEndBlock } from '../../theme';
import { Loader } from '../../components/Loader';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { uniqBy } from 'lodash';
import { useIntl } from 'react-intl';
const { getScientificPapersRandom, getScientificPapersWithKeyword } = api;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
    description: {
        lineHeight: '24px',
        marginTop: '40px'
    },
    papers: {
        margingTop: '20px',
    },
    container: {
        paddingLeft: "50px",
        paddingRight: "50px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        }
    },
    searchButton: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "5px",
            paddingRight: "5px",
        }
    },
    label: {
        "& > label": {
            [theme.breakpoints.down("xs")]: {
                fontSize: "0.8rem"
            }
        }
    },
    svg: {
      verticalAlign: "text-bottom",
      fontSize: "10px"
    },
}));

export function ScientificPapers() {
    const classes = useStyles();
    const theme = useTheme();
    const snackbar = useSnackbar();
    const [search, setSearch] = useState<string>('');
    const [scientificPapers, setScientificPapers] = useState<Array<IScientificPaper>>([]);
    const [page, setPage] = useState<number>(1);
    const [waiting, setWaiting] = useState<boolean>(true);
    const [trending, setTrending] = useState<boolean>(true);
    const { formatMessage } = useIntl();
    const undefineText = formatMessage({id: 'undefine_text'});
    let sciPapAppend = [];

    let nbItems = 0;

    const files = useSelector<AppState, IFile[]>((state) => state.app.files);    
    let scientificPapers_header = files.find(
        (file) => file.name === 'scientificPapers_header',
    );

    useEffect(() => {
        const getScientificPapers = async () => {
            let sciPap: IScientificPaper[];
            try {
                sciPap = await getScientificPapersRandom();
            } catch(e) {
                snackbar.showMessage(e.message);
                return;
            }

            // TODO: Par date
            sciPap.sort((a, b) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0));  
            setScientificPapers(sciPap);
        }
        if (trending){
            getScientificPapers();
        }
    }, [snackbar,trending]);

    const searchPapers = async (page:number, baseScientificPapers:IScientificPaper[]) => {
        setWaiting(true);
        sciPapAppend = [];
        let sciPap: IScientificPaper[];
        try {
            sciPap = await getScientificPapersWithKeyword(search, page);
        } catch(e) {
            snackbar.showMessage(e.message);
            return;
        }
        sciPapAppend = [...(baseScientificPapers||scientificPapers), ...sciPap];

        setScientificPapers(uniqBy(sciPapAppend, x => x.id));
        setPage(page);
        setWaiting(false);
    };

    if (scientificPapers.length && scientificPapers[0].totalHits){
        nbItems = scientificPapers[0].totalHits;
    }

    if (
        !scientificPapers_header
    ) {
        return <div>missing scientificPapers_header</div>;
    }

    const newSearch = () => {
        setTrending(false);
        setScientificPapers([]);
        searchPapers(1, []);
    }

    const showMoreFunction = (page:number) => async (event: React.FormEvent<HTMLElement>) => {
        searchPapers(page + 1, scientificPapers);
    }

    return <div className={classes.root}>
        <HeaderImage style={responsiveHeaderImage(theme)} title="Scientific papers" img={scientificPapers_header.file + "?size=original"} back={true}/>

        <Container maxWidth={false} className={classes.container}>
            <Typography component={'span'} variant="body2" className={classes.description}>
                <CleverFormattedMessage id="scientific_papers_description" />
            </Typography>

            <form>
                <Box display="flex" justifyContent="center">
                    <Grid container item xs={8} md={8}>
                        <Grid item xs={10}>
                            <TextField 
                                classes={{
                                    root: classes.label,
                                }}
                                id="search"
                                label="Search 135,539,113 papers"
                                variant="outlined"
                                fullWidth={true}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        newSearch()
                                        ev.preventDefault();
                                    }
                                  }}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                style={{marginLeft: '10px', width: '100%'}}
                                className={classes.searchButton}
                                onClick={() => newSearch()
                                }
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                {/* <Box display="flex" justifyContent="center">
                    <Grid container item xs={8} md={8}>
                        <Grid container item xs={10}>
                            <Grid item xs={4}>
                                <TextField
                                    id="start"
                                    select
                                    label="Start"
                                    variant="outlined"
                                >
                                    <MenuItem value="2000">2000</MenuItem>
                                    <MenuItem value="2001">2001</MenuItem>
                                    <MenuItem value="2002">2002</MenuItem>
                                    <MenuItem value="2003">2003</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    id="end"
                                    select
                                    label="End"
                                    variant="outlined"
                                >
                                    <MenuItem value="2000">2000</MenuItem>
                                    <MenuItem value="2001">2001</MenuItem>
                                    <MenuItem value="2002">2002</MenuItem>
                                    <MenuItem value="2003">2003</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    id="journal"
                                    label="Journal"
                                    select
                                    variant="outlined"
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="category">Category</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box> */}
            </form>

            <Box>
                {trending && (
                    <SubTitle text="trending_scientific_papers"/>
                )}
                {!trending && (
                    <SubTitle text="result_scientific_papers" subText={'(' + nbItems + ' items)'}/>
                )}
                {scientificPapers.length === 0 && (
                    <React.Fragment>
                        {waiting && (
                            <Loader height='5vh' size='50px'/>
                        )}
                        {!waiting && (
                            <CleverFormattedMessage id="scientific_papers_not_found" className={seeAllStyleEndBlock}/>
                        )}
                    </React.Fragment>
                )}

                <Grid container spacing={3}>
                    {scientificPapers.map((scientificPaper, i) => 
                        <Grid item xs={12} md={12}>
                            <a href={scientificPaper.content} key={scientificPaper.content} rel="noopener noreferrer" target="_blank">
                            {/* <Link to={"/scientific-papers/" + scientificPaper.id} key={i} className={classes.papers}> */}
                                <PaperCard
                                    title={scientificPaper.title}
                                    category="Interactive"
                                    link="PDF"
                                    date={/*TODO: maybe do better with dates! */ (scientificPaper.productionYear || undefineText).toString()}
                                    source="core.ac.uk"
                                    description={scientificPaper.description}
                                    showMore={true}
                                />
                            </a>
                        </Grid>
                    )}
                    <React.Fragment>
                        {!trending && !waiting && scientificPapers.length > 0 && scientificPapers.length !== nbItems && (
                            <Grid container justify="flex-end">
                                <Button color="primary" onClick={showMoreFunction(page)}><CleverFormattedMessage id="see_more_scientific_papers" className={seeAllStyleEndBlock}/><ArrowForwardIosIcon className={classes.svg} /></Button>
                            </Grid>
                        )}
                        {!trending && waiting && scientificPapers.length > 0 && (
                            <Grid container justify="flex-end">
                                <Loader height='5vh' size='50px'/>
                            </Grid>
                        )}
                    </React.Fragment>
                </Grid>
            </Box>
        </Container>
    </div>
    ;
}