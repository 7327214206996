import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { HeaderImage, CleverFormattedMessage } from 'videokube-front-library';
import { Container, TextField } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { IScientificPaper, Media } from 'videokube-front-library/dist/types';
import { searchBar } from '../../theme';
import { api } from "videokube-front-library";
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { Loader } from '../../components/Loader';
import { debounce } from 'lodash';
import { useIntl } from "react-intl";
import { GenericMediaViewer } from '../../components/GenericMediaViewer';
const { getMedias } = api;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '100vh',
        },
        container: {
            paddingLeft: "50px",
            paddingRight: "50px",
            [theme.breakpoints.down("sm")]: {
                paddingLeft: "16px",
                paddingRight: "16px",
            }
        },
        ...searchBar(theme)
    }));

export function Search() {
    let { search } = useParams<{ search: string }>();
    const [videos, setVideos] = useState<Media[]>([]);
    const [infographics, setInfographics] = useState<Media[]>([]);
    const [scientificPapers, setScientificPapers] = useState<IScientificPaper[]>([]);
    const snackbar = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let isCurrent = true;
        const fetchMedias = async () => {
            let allMedias: Media[] = [];
            setVideos([]);
            setInfographics([]);
            setScientificPapers([]);
            setLoading(true);

            try {
                allMedias = await getMedias({
                    orderBy: "productionYear",
                    sense: "desc",
                    query: search,
                    limit: 100
                });
                if (isCurrent) {
                    setVideos(allMedias.filter(media => media.typeOfContent?.toLowerCase() === "videos"));
                    setInfographics(allMedias.filter(media => media.typeOfContent?.toLowerCase() === "infographics"));
                    setScientificPapers(allMedias.filter(media => media.typeOfContent?.toLowerCase() === "scientific  paper"));
                    setLoading(false);
                }
            } catch (e) {
                snackbar.showMessage(e.message);
                return;
            }
        }
        fetchMedias();
        return () => {
            isCurrent = false;
        }
    }, [snackbar, search]);

    const theme = useTheme();
    const classes = useStyles(theme);
    const { formatMessage } = useIntl();
    const searchBarPlacehoder = formatMessage({ id: 'search_bar_placeholder' })


    const history = useHistory();
    const onSearch = (valeur: string) => {
        history.push("/search/" + valeur);
    }
    const onSearchDebounced = debounce(onSearch, 500);
    return <div className={classes.root}>
        <Hidden smUp>
            <Container className={classes.container}>
                <TextField
                    variant="outlined"
                    margin="none"
                    required
                    placeholder={searchBarPlacehoder}
                    autoFocus
                    className={classes.searchBar}
                    defaultValue={search}
                    onChange={(event) => onSearchDebounced && onSearchDebounced(event.target.value)}
                />
            </Container>
        </Hidden>
        {loading && <Loader />}

        {!loading &&
            <Container maxWidth={false} style={{ padding: '0px' }}>
                {(videos.length > 0 || infographics.length > 0 || scientificPapers.length > 0) && (
                    <GenericMediaViewer videos={videos} infographics={infographics} scientificPapers={scientificPapers} shouldDisplayScientificPapers={true} title={search} />
                )}
                {(videos.length === 0 && infographics.length === 0 && scientificPapers.length === 0) && (
                    <React.Fragment>
                        <HeaderImage title={search} />
                        <Typography align="center" color="primary" component="h4" variant="h4">
                            <CleverFormattedMessage id="search_not_found" />
                        </Typography>
                    </React.Fragment>
                )}
            </Container>}
    </div>;
}
