import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation, matchPath, useParams, useHistory } from 'react-router-dom';
import { Hidden, MenuItem, MuiThemeProvider, useTheme } from '@material-ui/core';
import { Topics } from './pages/Topics/Topics';
import { Category } from './pages/Category/Category';
import { Keyword } from './pages/Keyword';
import { Vod } from './pages/Videos/Vod';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { Register } from './pages/Register/Register';
import { Donate } from './pages/Donate/Donate';
import { About } from './pages/About';
import { Tv } from './pages/Videos/Tv';
import { Infographics } from './pages/Infographics/Infographics';
import { Infographic } from './pages/Infographics/Infographic';
import { Contact } from './pages/Contact/Contact';
import { ScientificPapers } from './pages/ScientificPapers/ScientificPapers';
import { ScientificPaper, GemScientificPaper } from './pages/ScientificPapers/ScientificPaper';
import { Search } from './pages/Search/Search';
import { UniversityClasses } from './pages/UniversityClasses/UniversityClasses';
import { Account } from './pages/Account/Account';
import {
  Navbar,
  Footer,
  SimpleRichText,
  MenuDropDown,
  useStylesNavbar,
  dropdownRedirectGen,
  useCanonicalUrl,
  stripHtml,
  videokubeUtils,
  initFacebook
} from 'videokube-front-library';
import { DefaultTheme, getTheme, navbarStyle } from './theme';
import { debounce, zipObject } from 'lodash';
import { IntlProvider } from 'react-intl';
import { TextsContext } from './utils/Context';
import { finalReducer, AppState } from './store';
import { createStore } from 'redux';
import { api, utils } from 'videokube-front-library';
import {Provider, useSelector} from "react-redux";
import { IText, JwtPayload, IFile, InterfaceConfiguration, Category as ICategory, Media } from 'videokube-front-library/dist/types';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import { ThankYou } from "./pages/ThankYou";
import './loader.css';
import { Loader } from './components/Loader';
import { ProviderView } from "./pages/ProviderView";
//import { getCaptchaSiteKey } from './common';
import ScrollToTop from './components/ScrollToTop';
import "core-js/stable";
import "regenerator-runtime/runtime";
import moment from 'moment';
import ShareIcon from '@material-ui/icons/Share';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, LinkedinIcon, LinkedinShareButton, EmailIcon, EmailShareButton } from "react-share";
import { Helmet } from 'react-helmet';
import { socialNetworkLink } from './common';

//import { WebComponentGenerator } from './webcomponents/tools';
//import "./webcomponents";
const { sleep } = utils;

const disableLogin = true;

const {getChannels, getVideoLink, getFiles, getCategories, getTexts, getInterfaceConfiguration, pingMe} = api;

const store = createStore(
  finalReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);

const dispatch = store.dispatch;

const getAndSetTexts = async () => {
  const texts = await getTexts();
  dispatch({ type: 'SET_TEXTS', data: texts });
};

const getAndSetInterfaceConfiguration = async () => {
  const interfaceConfiguration = await getInterfaceConfiguration();
  dispatch({
    type: 'SET_INTERFACE_CONFIGURATION',
    data: interfaceConfiguration,
  });
};

const getChannelsAndRefreshThemInStore = async () => {
  const channels = await getChannels();
  dispatch({ type: 'SET_CHANNELS', data: channels });
  return channels;
}

const handleChannelsAndTvLink = async () => {
  try{
    const currentMainChannel = store.getState().app.mainChannel;
    const channels = await getChannelsAndRefreshThemInStore();
  
    if (channels.length) {
      //We know that it WILL be a media as we know that the array is not empty!
      let channel = videokubeUtils.getChannelToDisplay(channels) as Media;
      
      if(currentMainChannel && channel.id === currentMainChannel.id){
        //We don't want to update the link in this case!
        return;
      }
      const tvVideoLink = await getVideoLink(channel.id);
      dispatch({ type: 'SET_TV_VIDEO_LINK', data: tvVideoLink });
    }
  } catch(e){
    return;
  }
};

utils.setIntervalBetweenTwoCallsToFunction(handleChannelsAndTvLink, 60000);

//Every 30 seconds, we try to renew the channels!
//setIntervalBetweenTooCallsToFunction(getChannelsAndRefreshThemInStore, 30000);
//TODO: this should be improved now that we get all programs at once!
const refreshProperlyChannels = async () => {
  while(true){
    const allChannels = await getChannelsAndRefreshThemInStore();
    const mainChannel = videokubeUtils.getChannelToDisplay(allChannels);
    if(!mainChannel){
      return;
    }
    const currentProgram = videokubeUtils.getCurrentProgram(mainChannel);
    if (!currentProgram){
      return;
    }
    const endOfCurrentProgram = moment(currentProgram.end);
    let timeDifference = endOfCurrentProgram.diff(moment());

    if(timeDifference < 0){
      timeDifference = 30000;
    }
    
    await sleep(timeDifference);
  }
}

refreshProperlyChannels()

// const getAndSetEvents = async () => {
//   const events = await getEvents();
//   dispatch({ type: 'SET_EVENTS', data: events });
// }

const getAndSetFiles = async () => {
  const files = await getFiles();
  dispatch({ type: 'SET_FILES', data: files });
};

const init = async () => {
  const arrayOfPromises: Array<any> = [
    getAndSetInterfaceConfiguration(),
    getAndSetTexts(),
    getCategories(true).then((categories) =>
      dispatch({ type: 'SET_CATEGORIES', data: categories }),
    ),
    handleChannelsAndTvLink(),
    getAndSetFiles(),
    //getAndSetEvents(),
    pingMe().then(aPayload => {
      if(!aPayload){
        return;
      }
      dispatch({ type: 'SET_JWT_PAYLOAD', data: aPayload});
    }).catch(error => {
      //We catch the error : the user is simply not connected!
    })
  ];
  try {
    localStorage.removeItem('socialLoginSkip');
    await Promise.all(arrayOfPromises);
    dispatch({ type: 'SET_READY', data: null });
  } catch (e) {
    //TODO: we should have an alert component ?
    console.log('ERROR while loading necessary data', e);
    //We don't set the page as ready... So it stays as "Loading" for ever. Intentional!
  }
};

initFacebook(videokubeUtils.getFacebookAppId());
init();

const Landing = () => {
  let { landing } = useParams<{landing:string}>();
  const texts = useSelector<AppState, IText[]>((state) => state.app.texts);
  const landingUrl = texts.find(x => x.key === "landing_" + landing) ;
  if(!landingUrl){
    return <SimpleRichText titleId="page_not_found" contentId="page_not_found" />;
  }
  const queryParams = new URLSearchParams(new URL(landingUrl.value).search);
  const heightParam = queryParams.get('height');
  if (heightParam){
    let height = parseInt(heightParam,10);
    return <iframe title={landing} src={landingUrl.value} scrolling="no" style={{width: "100%", height: height, borderStyle: "solid", borderWidth: "0px"}}></iframe>
  }
  return <Loader/>;
}

interface IRoute {
  name: string;
  component: string;
  path: string;
  withoutLayout?: boolean;
  allowAnonymous?: boolean;
}

const routesDb: IRoute[] = [
  { name: 'Home', path: '/', component: 'About' },
  { name: 'AboutUs', path: '/about', component: 'AboutUs' },
  { name: 'Donate', path: '/donate', component: 'Donate' },
  { name: 'Contact', path: '/contact', component: 'Contact' },
  { name: 'Landing', path: '/landing/:landing', component: 'Landing' },
  { name: 'ThankYou', path: '/thank-you/:uuid', component: 'ThankYou', withoutLayout: true, allowAnonymous: true  },
  { name: 'LoginPage', path: '/login', component: 'LoginPage', withoutLayout: true, allowAnonymous: true },
  { name: 'Register', path: '/register', component: 'Register', withoutLayout: true, allowAnonymous: true  },
  { name: 'Topics', path: '/topics', component: 'Topics' },
  { name: 'Category', path: '/category/:slug', component: 'Category' },
  { name: 'Keyword', path: '/keyword/:slug', component: 'Keyword' },
  { name: 'Search', path: '/search/:search', component: 'Search' },
  { name: 'SearchEmpty', path: '/search/', component: 'Search' },
  { name: 'ProviderView', path: '/provider/:slug', component: 'ProviderView' },
  { name: 'Subcategory', path: '/subcategory/:slug', component: 'Keyword' },
  { name: 'Vod', path: '/vod/:slug', component: 'Vod' },
  { name: 'Tv', path: '/tv', component: 'Tv' },
  { name: 'Infographics', path: '/infographics/:slug', component: 'Infographic'},
  { name: 'Infographics', path: '/infographics', component: 'Infographics'},
  { name: 'ScientificPapers', path: '/scientific-papers', component: 'ScientificPapers'},
  { name: 'ScientificPaper', path: '/scientific-papers/:slug', component: 'ScientificPaper'},
  { name: 'ScientificPaper', path: '/gem-scientific-papers/:slug', component: 'GemScientificPaper'},
  { name: 'UniversityClasses', path: '/university-classes', component: 'UniversityClasses'},
  { name: 'TermsConditions', path: '/terms-and-conditions', component: 'TermsConditions'},
  { name: 'Advisors', path: '/advisors', component: 'Advisors'},
  { name: 'Partners', path: '/partners', component: 'Partners'},
  { name: 'Account', path: '/account', component: 'Account' },
];

const linksLeft = [
  {
    slug: '/tv',
    title: 'live_title',
  }
];

const linksRight = [
  {
    slug: '/donate',
    titleKey: 'donate_title',
  }
];
const linksMiddle = [
  {
    slug: '/keyword/kids',
    titleKey: 'kids_title',
  }
]

const linksFooter = [
  {
    slug: 'https://gema.mc',
    title: 'gema_foundation_title'
  },
  {
    slug: '/donate',
    title: 'donate_title',
  },
  {
    slug: '/partners',
    title: 'partners_title',
  },
  {
    slug: '/advisors',
    title: 'advisors_title',
  },
  {
    slug: '/about',
    title: 'about_us_title',
  },
  {
    slug: '/terms-and-conditions',
    title: 'terms_and_conditions_title',
  },
  {
    slug: '/contact',
    title: 'contact_title',
  }
];

//This create a fake asynchronous routing (for instance coming from a backend server)
const getRoutesDb = async () => {
  return routesDb;
};

const CustomNavbarComponent = () => {
  const theme = useTheme();
  let classesNavbar = useStylesNavbar(navbarStyle(theme))();
  let classesNavbarMobile = useStylesNavbar(navbarStyle(theme),"auto")();

  const fullPath = useCanonicalUrl();

  const getMenuDropDownBody = (mobileMode = false) => {
    const calculatedClasses = mobileMode ? classesNavbarMobile : classesNavbar;
    return <MenuDropDown
      noHoverMode={mobileMode}
      dropdown={{
        title: 'share',
        links: [
          {
            slug: '',
            customComponent: (
              <MenuItem >
                <FacebookShareButton url={fullPath}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </MenuItem>
            ),
          },
          {
            slug: '',
            customComponent: (
              <MenuItem>
                <TwitterShareButton url={fullPath}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </MenuItem>
            ),
          },
          {
            slug: '',
            customComponent: (
              <MenuItem>
                <LinkedinShareButton url={fullPath}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </MenuItem>
            ),
          },
          {
            slug: '',
            customComponent: (
              <MenuItem>
                <EmailShareButton url={fullPath}>
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </MenuItem>
            ),
          },
        ],
        customIcon: <ShareIcon />,
      }}
      index={10}
      classes={calculatedClasses}
      dropdownRedirect={dropdownRedirectGen(calculatedClasses)}
    />
  };

  return (
    <>
      {/* Desktop */}
      <Hidden smDown>{getMenuDropDownBody(false)}</Hidden>
      {/* Mobile */}
      <Hidden mdUp>{getMenuDropDownBody(true)}</Hidden>
    </>
  );
};

const CommonMetaDatas = () => {
  const fullPath = useCanonicalUrl()
  return <Helmet>
      <meta property="og:url" content={fullPath} />
      <meta name="twitter:card" content="summary" />
  </Helmet>
}

function App() {
  let categs = useSelector<AppState, ICategory[]>(state => state.app.categories);
  const [routes, setRoutes] = useState<IRoute[]>([]);
  
  const ready = useSelector<AppState, boolean>((state) => state.app.ready);
  const texts = useSelector<AppState, IText[]>((state) => state.app.texts);
  const files = useSelector<AppState, IFile[]>((state) => state.app.files);
  const jwtPayload = useSelector<AppState, JwtPayload>((state) => state.app.jwtPayload);

  const location = useLocation();

  const currentRoute = routes.find(
    route => matchPath(location.pathname,{exact:true,path:route.path})
  )

  const interfaceConfiguration = useSelector<AppState, InterfaceConfiguration>(
    (state) => state.app.interfaceConfiguration,
  );

  const history = useHistory();

  let theme = DefaultTheme;
  
  if(interfaceConfiguration){
    theme = getTheme(interfaceConfiguration);
  }

  useEffect(() => {
    getRoutesDb().then(someRoutes => setRoutes(someRoutes));
  }, []);

  const textsKeyValue = zipObject(
    texts.map(x => x.key),
    texts.map(x => x.value),
  );

  let loggedInUser:string | null = null;

  if(jwtPayload){
    loggedInUser = jwtPayload.username;
  }

  interface SlugTitle {
    slug: string;
    title?: string;
    titleKey?: string;
  }

  let categoriesDropdown = categs.map(categ => {
    let cat: SlugTitle = { title: categ.name, slug: '/category/' + categ.slug };
    return cat;
  });

  let dropdowns = [
    {
      title: 'topics_title',
      links: categoriesDropdown
    },
    {
      title: 'learn_more_title',
      links: [
        {
          slug: '/infographics',
          titleKey: 'infographics_title'
        },
        {
          slug: '/scientific-papers',
          titleKey: 'scientific_papers_title'
        },
        {
          slug: '/university-classes',
          titleKey: 'university_classes_title'
        }
      ]
    }
  ];



  if (!ready) {
    return(
      <Loader />
    );
  }

  const logo = files.find(file => file.name === "logo");
  if(!logo){
    return (<div>Logo not found</div>);
  }

  let metaTitle = texts.find(x => x.key === "meta_title");
  let metaDescription = texts.find(x => x.key === "meta_description");
  let favicon = files.find(x => x.name === "favicon");

  const SocialLogin = () => {
    // login
    let socialLoginSkip = localStorage.getItem('socialLoginSkip');
    if (socialLoginSkip || jwtPayload || (currentRoute && currentRoute.allowAnonymous)){
      return <React.Fragment/>
    }
    return <LoginPage/>
  }

  const onSearch = (valeur: string) => {
    history.push("/search/" + valeur);
  }
  const onSearchDebounced = debounce(onSearch, 500)

  const switchStuff = routes.map(aRoute => {
    const withLayout = !aRoute.withoutLayout;
    const CurrentComponent = componentsDb[aRoute.component];
    return (
      <Route key={aRoute.path} exact path={aRoute.path}>
        {withLayout &&
          <Navbar
            style={navbarStyle(theme)}
            logo={logo.file}
            linksLeft={linksLeft}
            linksMiddle={linksMiddle}
            linksRight={linksRight}
            dropdowns={dropdowns}
            login
            loggedInUser={loggedInUser}
            loginRedirectUrl={"/topics"}
            useLoginPageUrl={"/login"}
            customComponent={<CustomNavbarComponent />}
            searchBar={true}
            onSearch={onSearchDebounced}
            searchProvider="routing"
            />
        }
          <SocialLogin/>
          <CommonMetaDatas />
          <Helmet>
            {metaTitle && [<meta property="og:title" content={metaTitle.value} />,<title>{metaTitle.value}</title>]}
            {metaDescription && [<meta property="og:description" content={stripHtml(metaDescription.value)} />,<meta name="description" content={stripHtml(metaDescription.value)} />]}
            {favicon && <link rel="icon" href={favicon.file} />}
            {favicon && <link rel="apple-touch-icon" href={favicon.file} />}
            {<meta property="og:image" content={logo.file} />}
          </Helmet>
          <CurrentComponent />
        {withLayout &&
          <Footer instagram={socialNetworkLink.instagram} facebook={socialNetworkLink.facebook} twitter={socialNetworkLink.twitter} youtube={socialNetworkLink.youtube} linkedin={socialNetworkLink.linkedin} links={linksFooter} otherColor={{color:theme.palette.secondary.main, backgroundColor:"#555555"}} />
        }
      </Route>
    );
  });

  /*const myElementExists = !!window.customElements.get('videokube-partner-new');
  if (!myElementExists) {
    window.customElements.define(
      'videokube-partner-new',
      WebComponentGenerator(
        () => {
          return (
            <Provider store={store}>
              <TextsContext.Provider value={textsKeyValue}>
                <IntlProvider locale="fr" messages={textsKeyValue}>
                <MuiThemeProvider theme={theme}>
                  <Partners />
                  </MuiThemeProvider>
                </IntlProvider>
              </TextsContext.Provider>
            </Provider>
          );
        },
        '',
        [],
      ),
    );
  }
  const htmlToDisplay = `<videokube-partner-new></videokube-partner-new>`;
  return <div style={{textAlign:'center'}} dangerouslySetInnerHTML={{ __html: htmlToDisplay }}></div>;
  */

  

  //TODO: handle the register page that CAN be visited as anonymous!

  return (
    <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
    <TextsContext.Provider value={textsKeyValue}>
      <IntlProvider locale="fr" messages={textsKeyValue}>
        <MuiThemeProvider theme={theme}>
          <ScrollToTop />
          {disableLogin || (loggedInUser || (currentRoute && currentRoute.allowAnonymous)) ? <div className="App">
            <Switch>{switchStuff}</Switch>
          </div> : <LoginPage />}
        </MuiThemeProvider>
      </IntlProvider>
    </TextsContext.Provider>
    </SnackbarProvider>
  );
}

const TermsConditions = () => {
  const files = useSelector<AppState, IFile[]>((state) => state.app.files);
  const headerImage = files.find(x => x.name === "terms_conditions_header");

  if (headerImage) {
    return <SimpleRichText titleId="terms_conditions_title" contentId="terms_conditions_content" headerImage={headerImage} />
  } else {
    return <SimpleRichText titleId="terms_conditions_title" contentId="terms_conditions_content" />
  }
};

const Advisors = () => {
  const files = useSelector<AppState, IFile[]>((state) => state.app.files);
  const headerImage = files.find(x => x.name === "advisors_header");

  if (headerImage) {
    return <SimpleRichText titleId="advisors_title" contentId="advisors_content" headerImage={headerImage} />
  } else {
    return <SimpleRichText titleId="advisors_title" contentId="advisors_content" />
  }
};

const AboutUs = () => {
  const files = useSelector<AppState, IFile[]>((state) => state.app.files);
  const headerImage = files.find(x => x.name === "about_us_header");

  if (headerImage) {
    return <SimpleRichText titleId="about_us_title" contentId="about_us_content" headerImage={headerImage} />
  } else {
    return <SimpleRichText titleId="about_us_title" contentId="about_us_content" />
  }
};

const Partners = () => {
  const files = useSelector<AppState, IFile[]>((state) => state.app.files);
  const headerImage = files.find(x => x.name === "partners_header");

  if (headerImage) {
    return <SimpleRichText titleId="partners_title" contentId="partners_content" headerImage={headerImage} />
  } else {
    return <SimpleRichText titleId="partners_title" contentId="partners_content" />
  }
};

const componentsDb: { [id: string]: () => JSX.Element } = {
  About,
  AboutUs,
  Contact,
  Topics,
  Category,
  Vod,
  Tv,
  Infographics,
  Infographic,
  ScientificPapers,
  ScientificPaper,
  GemScientificPaper,
  UniversityClasses,
  TermsConditions,
  Advisors,
  Keyword,
  Search,
  LoginPage,
  Register,
  ThankYou,
  Partners,
  Donate,
  ProviderView,
  Landing,
  Account
};
const FinalApp = () => <Provider store={store}>
  <Router>
    <App />
  </Router>
</Provider>

export default FinalApp;
