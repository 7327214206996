import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { SimpleCard, SubTitle, CleverFormattedMessage, IFrameViewer, PaperCard } from 'videokube-front-library';
import { Container} from '@material-ui/core';
import {useSelector} from "react-redux";
import { AppState } from '../../store';
import { Media, IScientificPaper } from 'videokube-front-library/dist/types';
import { useParams } from 'react-router-dom';
import { simpleCardNewStyle, simpleCardInfographicsNewStyle } from '../../theme';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { api } from 'videokube-front-library';
import { useIntl } from 'react-intl';
const { getIdWithSlug, getScientificPapersWithMedia, getMedias } = api;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        marginBottom: '40px',
    },
    container: {
        paddingLeft: "50px",
        paddingRight: "50px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        }
    },
    description: {
        paddingLeft: "18vw",
        paddingRight: "18vw",
        letterSpacing: "0px",
        color: "#9B9B9B",
        font: '12px Montserrat',
        fontSize: '12px',
    },
    dataDescription: {
        letterSpacing: "0px",
        color: "#9B9B9B",
        font: 'Regular 12px Montserrat',
        fontSize: '12px',
    },
    divider: {
        marginTop: '40px',
        height: '2px',
        color: theme.palette.primary.main,
    },
    imgContainer: {
        width: "100%",
        textAlign: 'left',
        marginTop: '10px',
    },
    exploreTitle: {
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '9.6px',
        color: '#9B9B9B',
        fontWeight: 'bold',
        marginTop: "50px",
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.55rem',
            letterSpacing: '5px'
        }
    },
    img:{
        maxHeight: '80vh',
        width: '100%',
    },
    dataSource: {
        paddingTop: '10px',
        textAlign: 'left',
        textTransform: 'uppercase',
        textDecoration: 'underline',
        fontSize: '10px',
        color: theme.palette.primary.main,
        '& a': {
            color: theme.palette.primary.main,
        }
    },
    similarVideos:{
      marginBottom: '24px'
    },
    papers: {
        marginBottom: '20px'
    },

}));

export function Infographic() {
    const classes = useStyles();
    const [mediaSlug, setMediaSlug] = useState<Media | null>(null);
    const [linkedMedias, setLinkedMedias] = useState<Media[]>([]);

    const [papers, setPapers] = useState<Array<IScientificPaper>>([]);
    const { formatMessage } = useIntl();
    const undefineText = formatMessage({id: 'undefine_text'});

    let { slug } = useParams<{slug:string}>();
    const snackbar = useSnackbar();
    
    const number_of_related_videos =
    (useSelector<AppState>(
      (state) => state.app.interfaceConfiguration?.number_of_related_videos,
    ) as number) || 12;


    useEffect(() => {
        if (!slug) {
            return;
        }
      const goPapers = async () => {
        try {
          let sciPap = await getScientificPapersWithMedia(slug);
          setPapers(sciPap);
        } catch(e) {
          snackbar.showMessage(e.message);
          return;
        }
      }
      
      const getMediaIdWithSlug = async () => {
        const mediasFromSlug = await getIdWithSlug(slug);
        if (!mediasFromSlug) {
          return;
        }
        //TODO: why ever is this an array?
        let media = mediasFromSlug[0];
        if (!media) {
          return;
        }
        setMediaSlug(media);

        let linkedMedias = await getMedias({relatedToMedia:media.id, limit: 1000});
        setLinkedMedias(linkedMedias);
      };

      getMediaIdWithSlug();
      goPapers();
    }, [slug, snackbar]);

    let infographics = linkedMedias.filter(media => media.typeOfContent?.toLowerCase() === "infographics");
    let videos = linkedMedias.filter(media => media.typeOfContent?.toLowerCase() === "videos");

    return <div className={classes.root}>
        {mediaSlug  && mediaSlug.format && mediaSlug.format.toLowerCase() === "html".toLowerCase() && mediaSlug.urlSource &&
            <Container fixed >
                <Grid container direction="column">
                    <Grid className={classes.imgContainer} item xs>
                        <SubTitle text={mediaSlug.providerName ? mediaSlug.providerName : ""} />
                    </Grid>
                    <IFrameViewer title="Infographics" src={mediaSlug.urlSource}/>
                </Grid>
            </Container>
        }
        {mediaSlug && mediaSlug.format && mediaSlug.format.toLowerCase() === "image".toLowerCase() && mediaSlug.preview && (
            <Container fixed >
                <Grid container direction="column">
                    <Grid className={classes.imgContainer} item xs>
                        <SubTitle text={mediaSlug.providerName ? mediaSlug.providerName : ""} />
                    </Grid>
                    <Grid className={classes.imgContainer} item xs>
                        <img  src={mediaSlug.preview + '?size=original'} alt={mediaSlug.title} className={classes.img}/>
                    </Grid>
                    <Grid className={classes.dataSource} item xs>
                        {mediaSlug.urlSource && (
                        <a href={mediaSlug.urlSource} rel="noopener noreferrer" target="_blank">
                            <CleverFormattedMessage id="data_source" />
                        </a>
                        )}
                    </Grid>
                    <Grid className={classes.imgContainer} item xs>
                        <span className={classes.dataDescription}>{mediaSlug.description}</span>
                    </Grid>
                </Grid>
            </Container>
        )}
            <Container maxWidth={false} className={classes.container}>
                <Typography component="h3" variant="h4" className={classes.exploreTitle}>
                    <CleverFormattedMessage id="explore" />
                </Typography>

                <SubTitle text="related_infographics" path="/infographics" />

                <Grid container spacing={3}>
                {infographics.slice(0, number_of_related_videos).map((infographic, i) =>
                        <Grid key={i} item xs={6} md={3}>
                            <SimpleCard
                                style={simpleCardInfographicsNewStyle}
                                title={infographic.productionYear ? infographic.title + " (" + infographic.productionYear + ")" : infographic.title}
                                category={infographic.providerName ? infographic.providerName + ' - ' + infographic.typeOfContent : infographic.typeOfContent + ' '}
                                type="pdf"
                                img={infographic.preview}
                                path={"/infographics/" + infographic.slug}
                            />
                        </Grid>
                    )}
                </Grid>

                <SubTitle text="related_scientific_papers" path="/scientific-papers" />
                    <Grid container spacing={3}>
                        {papers.slice(0, 6).map((scientificPaper, i) =>
                            <Grid item xs={12} md={6} key={i}>
                                <a href={scientificPaper.content} key={scientificPaper.content} rel="noopener noreferrer" target="_blank">
                                    <PaperCard
                                        title={scientificPaper.title}
                                        category="Article"
                                        link="PDF"
                                        date={/*TODO: maybe do better with dates! */ (scientificPaper.productionYear || undefineText).toString()}
                                        source="core.ac.uk"
                                        description={scientificPaper.description}
                                        showMore={true}
                                    />
                                </a>
                            </Grid>
                        )}
                    </Grid>
                <SubTitle text="similar_videos" />

                <Grid container spacing={3} className={classes.similarVideos}>
                    {videos.slice(0, number_of_related_videos).map((video, i) => (
                        <Grid key={i} item xs={6} md={3}>
                        <SimpleCard
                            title={video.productionYear ? video.title + " (" + video.productionYear + ")" : video.title}
                            duration={video.duration}
                            img={video.preview+"?size=small"}
                            category={video.providerName ? video.providerName : undefined}
                            type="video"
                            path={'/vod/' + video.slug}
                            style={simpleCardNewStyle}
                        />
                        </Grid>
                    ))}
                </Grid>

        </Container>
    </div>
}