import React from 'react';
import Hidden from '@material-ui/core/Hidden';

interface ReviveAdProps {
  id: number;
}

interface responsiveSlugReviveAd {
  slug: string;
}

const listAdToCategory = [{
  slug: 'the-ocean',
  desktop: 5743,
  mobile: 5744,
},
{
  slug: 'forests',
  desktop: 5745,
  mobile: 5746,
},
{
  slug: 'water',
  desktop: 5747,
  mobile: 5748,
},
{
  slug: 'biodiversity',
  desktop: 5749,
  mobile: 5750,
},
{
  slug:'climate-change',
  desktop: 5751,
  mobile: 5752,
},
{
  slug:'energy',
  desktop: 5753,
  mobile: 5754,
},
{
  slug: 'food',
  desktop: 5755,
  mobile: 5756,
},
{
  slug: 'people-on-earth',
  desktop: 5757,
  mobile: 5758,
},
{
  slug: 'sustainable-living',
  desktop: 5759,
  mobile: 5760,
},
{
  slug: 'vod',
  desktop: 5741,
  mobile: 5742,
},
{
  slug: 'tv',
  desktop:5739,
  mobile:5740,
}];

export const ResponsiveWithSlugReviveAd = (props: responsiveSlugReviveAd) => {
  let ad = listAdToCategory.find(listAdToCategory => listAdToCategory.slug === props.slug);
  if (!ad){
    return null;
  }

return <div>
    <Hidden mdDown >
        <ReviveAd id={ad.desktop} />
    </Hidden>
    <Hidden lgUp>
        <ReviveAd id={ad.mobile} />
    </Hidden>
</div>
};

export const ReviveAd = (props: ReviveAdProps) => {
  const possibleOAOutput = (window as any).OA_output;
  if (! possibleOAOutput){
    return null;
  }

  const htmlToDisplay = possibleOAOutput[props.id];
  if (!htmlToDisplay) {
    return null;
  }

  return <div style={{textAlign:'center'}} dangerouslySetInnerHTML={{ __html: htmlToDisplay }}></div>;
};
